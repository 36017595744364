// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.email_email__PkE6T {
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Email/email.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,0BAAA;AACJ","sourcesContent":[".email{\n    font-size: 20px;\n    font-weight: 500;\n    line-height: 150%;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"email": `email_email__PkE6T`
};
export default ___CSS_LOADER_EXPORT___;
