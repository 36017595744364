import React, { useState } from 'react'
import Arrow from '../../images/svg/pink-arrow-down.svg';
import styles from './quesitonItem.module.scss';

interface IQuesitonItem{
    question: string;
    children: React.ReactNode;
}

const QuesitonItem = ({ question, children }: IQuesitonItem) => {

    const [isActive, setIsActive] = useState(false);

  return (
    <div className={isActive ? styles.questionItemActive : styles.questionItem} onClick={() => setIsActive((prev) => !prev)}>
        <div className={styles.questionItemMain}>
            <img loading='lazy' src={Arrow} alt="Toggle" width={24} height={13} />
            <h2 className={styles.question}>{question}</h2>
        </div>
        <div className={styles.questionItemAnswer}>{children}</div>
    </div>
  )
}

export default QuesitonItem