import React from 'react';
import Telephone from '../Telephone/Telephone';
import Email from '../Email/Email';
import Telegram from '../../images/svg/telegram-light.svg';
import WhatsUp from '../../images/svg/whatsup-light.svg';
import styles from './mobileContacts.module.scss';

const socials = [
  {id: 1, name: 'WhatsApp', href: '', icon: Telegram},
  {id: 2, name: 'Telegram', href: '', icon: WhatsUp},
]

const MobileContacts = () => {
  return (
    <div className={styles.mobileContacts}>
        <div className={styles.mobileContactsMain}>
          <div className={styles.telephoneWrapper}>
            <Telephone />
          </div>
          <div className={styles.mobileContactsSocialsLinks}>
              {socials.map((el) => (
                  <a href={el.href} className={styles.headerContactsSocialsLinksItem} key={el.id}>
                      <img loading='lazy' src={el.icon} alt={el.name} width={20} height={20} />
                  </a>
              ))}
          </div>
        </div>
        <div className={styles.emailWrapper}>
          <Email />
        </div>
    </div>
  )
}

export default MobileContacts