// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.telephone_telephone__OO99k {
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;
}

@media (max-width: 450px) {
  .telephone_telephone__OO99k {
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Telephone/telephone.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI;IACI,eAAA;IACA,gBAAA;IACA,iBAAA;EACN;AACF","sourcesContent":[".telephone{\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 18px;\n}\n\n@media(max-width: 450px){\n    .telephone{\n        font-size: 15px;\n        font-weight: 500;\n        line-height: 27px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"telephone": `telephone_telephone__OO99k`
};
export default ___CSS_LOADER_EXPORT___;
