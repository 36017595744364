// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileContacts_mobileContacts__a86sU {
  margin-top: 30px;
}
.mobileContacts_mobileContacts__a86sU a {
  font-size: 20px;
  font-weight: 500;
  line-height: 150%;
}

.mobileContacts_telephoneWrapper__utyHS a {
  color: var(--white);
}

.mobileContacts_mobileContactsMain__2XPvv {
  display: flex;
  align-items: flex-end;
}

.mobileContacts_mobileContactsSocialsLinks__2P4y- {
  margin-left: 10px;
}
.mobileContacts_mobileContactsSocialsLinks__2P4y- a:not(:first-child) {
  margin-left: 8px;
}

.mobileContacts_emailWrapper__P1p74 a {
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/components/MobileContacts/mobileContacts.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACR;;AAII;EACI,mBAAA;AADR;;AAKA;EACI,aAAA;EACA,qBAAA;AAFJ;;AAKA;EACI,iBAAA;AAFJ;AAII;EACI,gBAAA;AAFR;;AAOI;EACI,mBAAA;AAJR","sourcesContent":[".mobileContacts{\n    margin-top: 30px;\n    \n    a{\n        font-size: 20px;\n        font-weight: 500;\n        line-height: 150%;\n    }\n}\n\n.telephoneWrapper{\n    a{\n        color: var(--white)\n    }\n}\n\n.mobileContactsMain{\n    display: flex;\n    align-items: flex-end;\n}\n\n.mobileContactsSocialsLinks{\n    margin-left: 10px;\n\n    a:not(:first-child){\n        margin-left: 8px;\n    }\n}\n\n.emailWrapper{\n    a{\n        color: var(--white);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileContacts": `mobileContacts_mobileContacts__a86sU`,
	"telephoneWrapper": `mobileContacts_telephoneWrapper__utyHS`,
	"mobileContactsMain": `mobileContacts_mobileContactsMain__2XPvv`,
	"mobileContactsSocialsLinks": `mobileContacts_mobileContactsSocialsLinks__2P4y-`,
	"emailWrapper": `mobileContacts_emailWrapper__P1p74`
};
export default ___CSS_LOADER_EXPORT___;
