
import styles from './mobileMenuNav.module.scss';

const language = localStorage.getItem('language');

const mobileMenuNav = 
    language ? language === 'ru' ?
    [
        {id: 1, name: 'Главная страница', href: '/'},
        {id: 2, name: 'Способы оплаты', href: '/payment-methods'},
    ] :
    [
        {id: 1, name: 'Home page', href: '/'},
        {id: 2, name: 'Payment methods', href: '/payment-methods'},
    ] :
    [
    {id: 1, name: 'Home page', href: '/'},
    {id: 2, name: 'Payment methods', href: '/payment-methods'},
    ];
    

const mobileSubNavFirst =
    language ? language === 'ru' ?
    [
        {id: 1, name: 'АЭРОПОРТ ЛАРНАКИ', href: 'https://www.larnaca-airport.com/'},
        {id: 2, name: 'АЭРОПОРТ ПАФОС   ', href: 'https://www.airport-paphos.com/'},
    ] :
    [
        {id: 1, name: 'LARNACA AIRPORT', href: 'https://www.larnaca-airport.com/'},
        {id: 2, name: 'PAPHOS AIRPORT', href: 'https://www.airport-paphos.com/'},
    ] :
    [
        {id: 1, name: 'LARNACA AIRPORT', href: 'https://www.larnaca-airport.com/'},
        {id: 2, name: 'PAPHOS AIRPORT', href: 'https://www.airport-paphos.com/'},
    ]
     

const mobileSubNavSecond = 
    language ? language === 'ru' ?
    [
        {id: 1, name: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ', href: '/'},
        {id: 2, name: 'УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ УСЛУГ', href: '/'},
    ] :
    [
        {id: 1, name: 'PRIVACY POLICY', href: '/'},
        {id: 2, name: 'TERMS OF  SERVICE', href: '/'},
    ] :
    [
        {id: 1, name: 'PRIVACY POLICY', href: '/'},
        {id: 2, name: 'TERMS OF  SERVICE', href: '/'},
    ]

const MobileMenuNav = () => {
  return (
    <nav className="mobileMenuNavMain">
        <ul className={styles.mobileMenuNavMainList}>
            <li className="mobileMenuNavMainListItem">
                {mobileMenuNav.map((el) => (
                    <a href={el.href} className={styles.mobileMenuNavMainListLink} key={el.id}>{el.name}</a>
                ))}
            </li>
        </ul>
        <ul className={styles.mobileMenuSubNavList}>
            <li className="mobileMenuSubNavListItem">
                {mobileSubNavFirst.map((el) => (
                    <a href={el.href} className={styles.mobileMenuSubNavListLink} key={el.id}>{el.name}</a>
                ))}
            </li>
        </ul>
        <ul className={styles.mobileMenuSubNavList}>
            <li className="mobileMenuSubNavListItem">
                {mobileSubNavSecond.map((el) => (
                    <a href={el.href} className={styles.mobileMenuSubNavListLink} key={el.id}>{el.name}</a>
                ))}
            </li>
        </ul>
        
    </nav>
  )
}

export default MobileMenuNav