// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileMenu_mobileMenuWrapperActive__iz6Rx {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--dark-pink);
  padding: 55px 20px 200px 20px;
  z-index: 10;
  transition: left 0.4s;
}

.mobileMenu_mobileMenuWrapper__gcwpC {
  position: absolute;
  top: 0;
  left: -500px;
  background: var(--dark-pink);
  padding: 55px 20px 200px 20px;
  z-index: 10;
  transition: left 0.4s;
}

.mobileMenu_toggleWrapper__5OdtG {
  margin-top: 20px;
}
.mobileMenu_toggleWrapper__5OdtG div div {
  color: var(--white) !important;
}
.mobileMenu_toggleWrapper__5OdtG div div div {
  color: var(--black) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMenu/mobileMenu.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,4BAAA;EACA,6BAAA;EACA,WAAA;EACA,qBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,YAAA;EACA,4BAAA;EACA,6BAAA;EACA,WAAA;EACA,qBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACI;EACI,8BAAA;AACR;AAEI;EACI,8BAAA;AAAR","sourcesContent":[".mobileMenuWrapperActive{\n    position: absolute;\n    top: 0;\n    left: 0;\n    background: var(--dark-pink);\n    padding: 55px 20px 200px 20px;\n    z-index: 10;\n    transition: left 0.4s;\n}\n\n.mobileMenuWrapper{\n    position: absolute;\n    top: 0;\n    left: -500px;\n    background: var(--dark-pink);\n    padding: 55px 20px 200px 20px;\n    z-index: 10;\n    transition: left 0.4s;\n}\n\n.toggleWrapper{\n    margin-top: 20px;\n\n    div div{\n        color: var(--white) !important;\n    }\n\n    div div div{\n        color: var(--black) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenuWrapperActive": `mobileMenu_mobileMenuWrapperActive__iz6Rx`,
	"mobileMenuWrapper": `mobileMenu_mobileMenuWrapper__gcwpC`,
	"toggleWrapper": `mobileMenu_toggleWrapper__5OdtG`
};
export default ___CSS_LOADER_EXPORT___;
