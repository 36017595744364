// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileHiddenBlock_mobileHiddenBlock__kVTwj {
  display: none;
  padding-top: 12px;
}

.mobileHiddenBlock_mobileHiddenBlockMain__DK50o {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0px;
  border-top: 2px solid var(--dark-pink);
  border-bottom: 2px solid var(--dark-pink);
}
.mobileHiddenBlock_mobileHiddenBlockMain__DK50o .mobileHiddenBlock_mobileHiddenBlockMain__DK50o img {
  transition: transform 0.4s;
}

.mobileHiddenBlock_mobileHiddenBlockContent__Wde5- {
  display: none;
}

.mobileHiddenBlock_mobileHiddenBlockActive__KKL7q {
  display: none;
  padding-top: 12px;
}
.mobileHiddenBlock_mobileHiddenBlockActive__KKL7q .mobileHiddenBlock_mobileHiddenBlockContent__Wde5- {
  display: block;
}
.mobileHiddenBlock_mobileHiddenBlockActive__KKL7q .mobileHiddenBlock_mobileHiddenBlockMain__DK50o img {
  transform: rotateZ(-180deg);
  transition: transform 0.4s;
}

@media (max-width: 500px) {
  .mobileHiddenBlock_mobileHiddenBlock__kVTwj, .mobileHiddenBlock_mobileHiddenBlockActive__KKL7q {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MobileHiddenBlock/mobileHiddenBlock.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,sCAAA;EACA,yCAAA;AACJ;AACI;EACI,0BAAA;AACR;;AAGA;EACI,aAAA;AAAJ;;AAGA;EACI,aAAA;EACA,iBAAA;AAAJ;AAEI;EACI,cAAA;AAAR;AAGI;EACI,2BAAA;EACA,0BAAA;AADR;;AAKA;EACI;IACI,cAAA;EAFN;AACF","sourcesContent":[".mobileHiddenBlock{\n    display: none;\n    padding-top: 12px;\n}\n\n.mobileHiddenBlockMain{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 13px 0px;\n    border-top: 2px solid var(--dark-pink);\n    border-bottom: 2px solid var(--dark-pink);\n\n    .mobileHiddenBlockMain img{\n        transition: transform 0.4s;\n    }\n}\n\n.mobileHiddenBlockContent{\n    display: none;\n}\n\n.mobileHiddenBlockActive{\n    display: none;\n    padding-top: 12px;\n\n    .mobileHiddenBlockContent{\n        display: block;\n    }\n\n    .mobileHiddenBlockMain img{\n        transform: rotateZ(-180deg);\n        transition: transform 0.4s;\n    }\n}\n\n@media(max-width: 500px){\n    .mobileHiddenBlock, .mobileHiddenBlockActive{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileHiddenBlock": `mobileHiddenBlock_mobileHiddenBlock__kVTwj`,
	"mobileHiddenBlockMain": `mobileHiddenBlock_mobileHiddenBlockMain__DK50o`,
	"mobileHiddenBlockContent": `mobileHiddenBlock_mobileHiddenBlockContent__Wde5-`,
	"mobileHiddenBlockActive": `mobileHiddenBlock_mobileHiddenBlockActive__KKL7q`
};
export default ___CSS_LOADER_EXPORT___;
