import FlightSearch from '../../components/FlightSearch/FlightSearch';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import PricingTable from '../../components/PricingTable/PricingTable';
import ServiceItem from '../../components/ServiceItem/ServiceItem';
import QuesitonItem from '../../components/QuesitonItem/QuesitonItem';
import MobileHiddenBlock from '../../components/MobileHiddenBlock/MobileHiddenBlock';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import QuoteComponent from '../../components/QuoteComponent/QuoteComponent';

import PricingImage from '../../images/png/pricing-image.png';
import QuestionsImage from '../../images/png/questions-image.png';

import ServiceCardFirst from '../../images/png/service-card1.png';
import ServiceCardSecond from '../../images/png/service-card2.png';
import ServiceCardThird from '../../images/png/service-card3.png';

import styles from './homeScreen.module.scss';

const HomeScreen = () => {
  const language = localStorage.getItem('language');
  return (
      <main className="homeScreen">
      <div className="yellowWrapper">
        <div className="container">
          <section className={styles.sectionTop}>
            <div className={styles.sectionTopContent}>
              <h1 className={styles.mainTitle}>
                {language ? language === 'ru' ? 'Аэропорты Кипра VIP Fast Track Прибытие и вылет' : 'Cyprus Airports VIP Fast Track Arrivals & Departures' : 'Cyprus Airports VIP Fast Track Arrivals & Departures'}
              </h1>
              <p className={styles.mainDescr}>
                {language ? language === 'ru' ? 'Спланируйте и забронируйте свое идеальное путешествие, воспользовавшись квалифицированным VIP-обслуживанием, трансферами, советами по путешествиям, информацией о направлениях и вдохновением от нас.' : 'Plan and book your perfect trip with expert VIP service,  transfers, travel tips, destination information and inspiration from us.' : 'Plan and book your perfect trip with expert VIP service,  transfers, travel tips, destination information and inspiration from us.'}
              </p>
              <FlightSearch showLink={true} flight='firstFlight'/>
            </div>
          </section>
        </div>
      </div>
      <section className={styles.sectionServices}>
        <div className="container">
          <div className={styles.sectionServicesContent}>
            <div className={styles.sectionServicesContentItem}>
              {
              language ? language === 'ru' ?
              <SectionTitle mainWords='Прибытия' otherText='VIP обслуживание'/> :
              <SectionTitle mainWords='Arrivals' otherText='VIP service'/> :
              <SectionTitle mainWords='Arrivals' otherText='VIP service'/>
              }
              {
              language ? language === 'ru' ?
              <ul className={styles.servicesList}>
                <ServiceItem text='Встреча у дверей самолета или у трапа с табличкой с вашим именем.'/>
                <ServiceItem text="Быстрое прохождение формальностей в аэропорту: паспортный контроль, таможня, ветеринарный или санитарный контроль (при необходимости), в сопровождении представителя VIP-отдела компании."/>
                <ServiceItem text='Помощь в получении багажа и его транспортировке к автомобилю или на парковку.'/>
              </ul> :
              <ul className={styles.servicesList}>
                <ServiceItem text='Meeting at the plane door or the jetway with your nameplate.'/>
                <ServiceItem text="Fast passing through airport formalities: passport control, the customs, veterinary or sanitary inspection (if required), accompanied by the company's VIP department representative."/>
                <ServiceItem text='Assistance in luggage claim and its transportation to a car or a parking.'/>
              </ul> :
              <ul className={styles.servicesList}>
              <ServiceItem text='Meeting at the plane door or the jetway with your nameplate.'/>
              <ServiceItem text="Fast passing through airport formalities: passport control, the customs, veterinary or sanitary inspection (if required), accompanied by the company's VIP department representative."/>
              <ServiceItem text='Assistance in luggage claim and its transportation to a car or a parking.'/>
            </ul>
              }

            </div>
            <div className={styles.sectionServicesContentItem}>
              {
              language ? language === 'ru' ?
              <SectionTitle mainWords='Отправления' otherText='VIP обслуживание'/> :
              <SectionTitle mainWords='Departures' otherText='VIP service'/> :
              <SectionTitle mainWords='Departures' otherText='VIP service'/>
              }
              {
              language ? language === 'ru' ?
              <ul className={styles.servicesList}>
                <ServiceItem text='Встреча в аэропорту представителем VIP-отдела.'/>
                <ServiceItem text="Транспортировка багажа от парковки до стойки регистрации."/>
                <ServiceItem text='Приоритетная регистрация.'/>
                <ServiceItem text='Быстрое прохождение формальностей в аэропорту: паспортного контроля, таможни, ветеринарного или санитарного контроля (если требуется).'/>
                <ServiceItem text='Сопровождение до терминала вылета.'/>
                <ServiceItem text='Сопровождение в VIP-зал (если VIP-зал заказан), а затем на посадку.'/>
              </ul>:
              <ul className={styles.servicesList}>
              <ServiceItem text='Meeting at the airport by the VIP department representative.'/>
              <ServiceItem text="Luggage transportation from the parking to the check-in desk."/>
              <ServiceItem text='Priority check-in.'/>
              <ServiceItem text='Fast passing through airport formalities: passport control, the customs, veterinary or sanitary inspection (if required).'/>
              <ServiceItem text='Escort to the departure terminal.'/>
              <ServiceItem text='Escort to the VIP Lounge (if VIP Lounge is ordered) and then to boarding.'/>
              </ul> :
                <ul className={styles.servicesList}>
                <ServiceItem text='Meeting at the airport by the VIP department representative.'/>
                <ServiceItem text="Luggage transportation from the parking to the check-in desk."/>
                <ServiceItem text='Priority check-in.'/>
                <ServiceItem text='Fast passing through airport formalities: passport control, the customs, veterinary or sanitary inspection (if required).'/>
                <ServiceItem text='Escort to the departure terminal.'/>
                <ServiceItem text='Escort to the VIP Lounge (if VIP Lounge is ordered) and then to boarding.'/>
              </ul>
              }

            </div>
          </div>
        </div>
      </section>
      <div className={styles.sectionPricingWrapper}>
        <div className="container">
            <section className={styles.sectionPricing}>
              <div className={styles.sectionPricingContent}>
                {
                language ? language === 'ru' ?
                <SectionTitle mainWords='Наши цены' otherText='Прибытие и отъезд'/> :
                <SectionTitle mainWords='Our Price' otherText='Arrival of Departure'/> :
                <SectionTitle mainWords='Our Price' otherText='Arrival of Departure'/>
                }
                <img loading='lazy' src={PricingImage} alt="Pricing" width={191} height={175} className={styles.sectionPricingImage} />
                <div className={styles.pricingTableWrapper}>
                  <PricingTable />
                  {
                  language ? language === 'ru' ?
                  <ul className={styles.pricingServicesList}>
                    <ServiceItem text='Домашние животные: 35 €'/>
                    <ServiceItem text='Специальный багаж: 35 €'/>
                    <ServiceItem text='Дополнительный багаж, 3+ места/пассажир: 35 €.'/>
                    <ServiceItem text='Декларации Tax Free: 1-4 квитанции: 35 € / 5-9 квитанций: 60 €'/>
                  </ul> :
                  <ul className={styles.pricingServicesList}>
                  <ServiceItem text='Pets: 35 €'/>
                  <ServiceItem text='Special luggage: 35 €'/>
                  <ServiceItem text='Extra luggage, 3+ items/passenger: 35 €'/>
                  <ServiceItem text='Tax Free declarations: 1-4 receipts: 35 € / 5-9 receipts: 60 €'/>
                  </ul> :
                  <ul className={styles.pricingServicesList}>
                  <ServiceItem text='Pets: 35 €'/>
                  <ServiceItem text='Special luggage: 35 €'/>
                  <ServiceItem text='Extra luggage, 3+ items/passenger: 35 €'/>
                  <ServiceItem text='Tax Free declarations: 1-4 receipts: 35 € / 5-9 receipts: 60 €'/>
                  </ul>
                  }

                </div>
              </div>
            </section>
        </div>
      </div>
      <div className={styles.sectionQuestionsWrapper}>
        <div className="container">
          <section className={styles.sectionQuestions}>
            <div className={styles.sectionQuestionsContent}>
              {
              language ? language === 'ru' ?
              <div>
                <SectionTitle mainWords='Знакомство с Кипром' otherText='с Калимерой!'/>
                <div className={styles.sectionQuestionsText}>
                  <p className={styles.sectionQuestionsTextItem}>Уникальная услуга, предлагаемая в обоих аэропортах Кипра: VIP Fast Track - "Kalimera Meet&Greet".</p>
                  <p className={styles.sectionQuestionsTextItem}>Вы деловой человек, чей день расписан поминутно, или семья, путешествующая с детьми? А может быть, вы прилетели на Кипр с лучшими друзьями и не хотите тратить время на очереди на паспортный контроль, а затем на поиски подходящего трансфера?</p>
                  <p className={styles.sectionQuestionsTextItem}>Таким образом, VIP Fast Track "Kalimera Meet&Greet" - это идеальное решение для наших гостей, которые ценят свое время и комфорт!</p>
                  <p className={styles.sectionQuestionsTextItem}>Мы предлагаем VIP-услуги по прилету/отлету в аэропортах Ларнаки и Пафоса.</p>
                </div>
                <img loading='lazy' src={QuestionsImage} alt="Questions" width={151} height={164} className={styles.sectionQuestionImage} />
              </div>:
              <div>
              <SectionTitle mainWords='Meet Cyprus' otherText='with Kalimera!'/>
              <div className={styles.sectionQuestionsText}>
                <p className={styles.sectionQuestionsTextItem}>A unique service offered at both Cyprus airports: VIP Fast Track – “Kalimera Meet&Greet”.</p>
                <p className={styles.sectionQuestionsTextItem}>Are you a businessman whose day is scheduled by the minute or a family traveling with children? Or perhaps you have arrived in Cyprus with your best friends and do not want to waste time lining up for passport control and then searching for a suitable transfer? </p>
                <p className={styles.sectionQuestionsTextItem}>Thus VIP Fast Track “Kalimera Meet&Greet” is the perfect solution for our guests who value their time and comfort!</p>
                <p className={styles.sectionQuestionsTextItem}>We offer VIP services on arrival/departure at Larnaca and Paphos airports.</p>
              </div>
              <img loading='lazy' src={QuestionsImage} alt="Questions" width={151} height={164} className={styles.sectionQuestionImage} />
              </div>:
                <div>
                <SectionTitle mainWords='Meet Cyprus' otherText='with Kalimera!'/>
                <div className={styles.sectionQuestionsText}>
                  <p className={styles.sectionQuestionsTextItem}>A unique service offered at both Cyprus airports: VIP Fast Track – “Kalimera Meet&Greet”.</p>
                  <p className={styles.sectionQuestionsTextItem}>Are you a businessman whose day is scheduled by the minute or a family traveling with children? Or perhaps you have arrived in Cyprus with your best friends and do not want to waste time lining up for passport control and then searching for a suitable transfer? </p>
                  <p className={styles.sectionQuestionsTextItem}>Thus VIP Fast Track “Kalimera Meet&Greet” is the perfect solution for our guests who value their time and comfort!</p>
                  <p className={styles.sectionQuestionsTextItem}>We offer VIP services on arrival/departure at Larnaca and Paphos airports.</p>
                </div>
                <img loading='lazy' src={QuestionsImage} alt="Questions" width={151} height={164} className={styles.sectionQuestionImage} />
              </div>
              }
              {
              language ? language === 'ru' ?
              <div className={styles.questions}>
                <div className={styles.questionsTitle}>
                  <SectionTitle mainWords='FAQ' otherText='Нужна помощь?'/>
                  <QuesitonItem question='В каких аэропортах Kalimera Meet & Greet предоставляет услуги Fast Track?'>
                  Мы предоставляем эту услугу по всему миру. Компания располагает штатом сотрудников и собственным перронным транспортом в аэропорту Ларнаки (Кипр), а также заключила договоры на обслуживание с надежными партнерами в других аэропортах.
                  </QuesitonItem>
                  <QuesitonItem question='Когда я могу заказать услуги встречи и проводов?'>
                  Вы всегда можете забронировать услуги заранее. Бронирование, сделанное менее чем за 24 часа до вылета, подлежит дополнительному подтверждению с нашей стороны. Самое позднее возможное время бронирования - за 6 часов до вылета.
                  </QuesitonItem>
                  <QuesitonItem question='Принимаете ли вы оплату наличными / банковским переводом / криптовалютой?'>
                  Да, это так. Подробнее <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Способы оплаты</a>
                  </QuesitonItem>
                  <QuesitonItem question='Как отменить заказ?'>
                  Пожалуйста, запросите отмену по электронной почте <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                  </QuesitonItem>
                </div>
              </div> :
              <div className={styles.questions}>
              <div className={styles.questionsTitle}>
                <SectionTitle mainWords='FAQ' otherText='Need Help?'/>
                <QuesitonItem question='At which airports does Kalimera Meet & Greet provide Fast Track services?'>
                  We provide this service worldwide. The company has its staff and own apron transport at Larnaca Airport (Cyprus) and service agreements with reliable partners at other airports.
                </QuesitonItem>
                <QuesitonItem question='When can I book Meet & Greet Services?'>
                  You can always book the services in advance. Reservations made less than 24 hours prior to your flight are subject of additional confirmation by us. The latest possible reservation time is 6 hours prior to the flight.
                </QuesitonItem>
                <QuesitonItem question='Do you accept cash payment / bank transfer / cryptocurrency?'>
                  Yes, we do. More details on <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Payment methods</a>
                </QuesitonItem>
                <QuesitonItem question='How to cancel an order?'>
                Please request cancellation by email <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                </QuesitonItem>
              </div>
              </div> :
                <div className={styles.questions}>
                <div className={styles.questionsTitle}>
                  <SectionTitle mainWords='FAQ' otherText='Need Help?'/>
                  <QuesitonItem question='At which airports does Kalimera Meet & Greet provide Fast Track services?'>
                    We provide this service worldwide. The company has its staff and own apron transport at Larnaca Airport (Cyprus) and service agreements with reliable partners at other airports.
                  </QuesitonItem>
                  <QuesitonItem question='When can I book Meet & Greet Services?'>
                    You can always book the services in advance. Reservations made less than 24 hours prior to your flight are subject of additional confirmation by us. The latest possible reservation time is 6 hours prior to the flight.
                  </QuesitonItem>
                  <QuesitonItem question='Do you accept cash payment / bank transfer / cryptocurrency?'>
                    Yes, we do. More details on <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Payment methods</a>
                  </QuesitonItem>
                  <QuesitonItem question='How to cancel an order?'>
                  Please request cancellation by email <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                  </QuesitonItem>
                </div>
              </div>
              }

            </div>
          </section>
        </div>
      </div>
      <div className={styles.mobileHiddenBlocksWrapper}>
        <div className="container">
          {
          language ? language === 'ru' ?
          <MobileHiddenBlock title={<SectionTitle mainWords='Знакомство с Кипром' otherText='с Калимерой!'/>}>
            <div className={styles.sectionQuestionsText}>
              <p className={styles.sectionQuestionsTextItem}>Уникальная услуга, предлагаемая в обоих аэропортах Кипра: VIP Fast Track - "Kalimera Meet&Greet".</p>
              <p className={styles.sectionQuestionsTextItem}>Вы деловой человек, чей день расписан поминутно, или семья, путешествующая с детьми? А может быть, вы прилетели на Кипр с лучшими друзьями и не хотите тратить время на очереди на паспортный контроль, а затем на поиски подходящего трансфера?</p>
              <p className={styles.sectionQuestionsTextItem}>Таким образом, VIP Fast Track "Kalimera Meet&Greet" - это идеальное решение для наших гостей, которые ценят свое время и комфорт!</p>
              <p className={styles.sectionQuestionsTextItem}>Мы предлагаем VIP-услуги по прилету/отлету в аэропортах Ларнаки и Пафоса.</p>
            </div>
          </MobileHiddenBlock> :
          <MobileHiddenBlock title={<SectionTitle mainWords='Meet Cyprus' otherText='with Kalmera!'/>}>
          <div className={styles.sectionQuestionsText}>
            <p className={styles.sectionQuestionsTextItem}>A unique service offered at both Cyprus airports: VIP Fast Track – “Kalimera Meet&Greet”.</p>
            <p className={styles.sectionQuestionsTextItem}>Are you a businessman whose day is scheduled by the minute or a family traveling with children? Or perhaps you have arrived in Cyprus with your best friends and do not want to waste time lining up for passport control and then searching for a suitable transfer? </p>
            <p className={styles.sectionQuestionsTextItem}>Thus VIP Fast Track “Kalimera Meet&Greet” is the perfect solution for our guests who value their time and comfort!</p>
            <p className={styles.sectionQuestionsTextItem}>We offer VIP services on arrival/departure at Larnaca and Paphos airports.</p>
          </div>
          </MobileHiddenBlock> :
            <MobileHiddenBlock title={<SectionTitle mainWords='Meet Cyprus' otherText='with Kalmera!'/>}>
            <div className={styles.sectionQuestionsText}>
              <p className={styles.sectionQuestionsTextItem}>A unique service offered at both Cyprus airports: VIP Fast Track – “Kalimera Meet&Greet”.</p>
              <p className={styles.sectionQuestionsTextItem}>Are you a businessman whose day is scheduled by the minute or a family traveling with children? Or perhaps you have arrived in Cyprus with your best friends and do not want to waste time lining up for passport control and then searching for a suitable transfer? </p>
              <p className={styles.sectionQuestionsTextItem}>Thus VIP Fast Track “Kalimera Meet&Greet” is the perfect solution for our guests who value their time and comfort!</p>
              <p className={styles.sectionQuestionsTextItem}>We offer VIP services on arrival/departure at Larnaca and Paphos airports.</p>
            </div>
          </MobileHiddenBlock>
          }
          {
          language ? language === 'ru' ?
          <MobileHiddenBlock title={<SectionTitle mainWords='FAQ' otherText='Нужна помощь?'/>}>
          <div className={styles.questions}>
              <div className={styles.questionsTitle}>
                <SectionTitle mainWords='FAQ' otherText='Нужна помощь?'/>
                <QuesitonItem question='В каких аэропортах Kalimera Meet & Greet предоставляет услуги Fast Track?'>
                Мы предоставляем эту услугу по всему миру. Компания располагает штатом сотрудников и собственным перронным транспортом в аэропорту Ларнаки (Кипр), а также заключила договоры на обслуживание с надежными партнерами в других аэропортах.
                </QuesitonItem>
                <QuesitonItem question='Когда я могу заказать услуги встречи и проводов?'>
                Вы всегда можете забронировать услуги заранее. Бронирование, сделанное менее чем за 24 часа до вылета, подлежит дополнительному подтверждению с нашей стороны. Самое позднее возможное время бронирования - за 6 часов до вылета.
                </QuesitonItem>
                <QuesitonItem question='Принимаете ли вы оплату наличными / банковским переводом / криптовалютой?'>
                Да, это так. Подробнее <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Способы оплаты</a>
                </QuesitonItem>
                <QuesitonItem question='Как отменить заказ?'>
                Пожалуйста, запросите отмену по электронной почте <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                </QuesitonItem>
              </div>
            </div>
          </MobileHiddenBlock> :
          <MobileHiddenBlock title={<SectionTitle mainWords='FAQ' otherText='Need help?'/>}>
          <div className={styles.questions}>
              <div className={styles.questionsTitle}>
                <SectionTitle mainWords='FAQ' otherText='Need Help?'/>
                <QuesitonItem question='At which airports does Kalimera Meet & Greet provide Fast Track services?'>
                  We provide this service worldwide. The company has its staff and own apron transport at Larnaca Airport (Cyprus) and service agreements with reliable partners at other airports.
                </QuesitonItem>
                <QuesitonItem question='When can I book Meet & Greet Services?'>
                  You can always book the services in advance. Reservations made less than 24 hours prior to your flight are subject of additional confirmation by us. The latest possible reservation time is 6 hours prior to the flight.
                </QuesitonItem>
                <QuesitonItem question='Do you accept cash payment / bank transfer / cryptocurrency?'>
                  Yes, we do. More details on <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Payment methods</a>
                </QuesitonItem>
                <QuesitonItem question='How to cancel an order?'>
                Please request cancellation by email <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                </QuesitonItem>
              </div>
            </div>
          </MobileHiddenBlock> :
          <MobileHiddenBlock title={<SectionTitle mainWords='FAQ' otherText='Need help?'/>}>
          <div className={styles.questions}>
              <div className={styles.questionsTitle}>
                <SectionTitle mainWords='FAQ' otherText='Need Help?'/>
                <QuesitonItem question='At which airports does Kalimera Meet & Greet provide Fast Track services?'>
                  We provide this service worldwide. The company has its staff and own apron transport at Larnaca Airport (Cyprus) and service agreements with reliable partners at other airports.
                </QuesitonItem>
                <QuesitonItem question='When can I book Meet & Greet Services?'>
                  You can always book the services in advance. Reservations made less than 24 hours prior to your flight are subject of additional confirmation by us. The latest possible reservation time is 6 hours prior to the flight.
                </QuesitonItem>
                <QuesitonItem question='Do you accept cash payment / bank transfer / cryptocurrency?'>
                  Yes, we do. More details on <a href="/payment-methods" style={{color: '#0755B1', textDecoration: 'underline'}}>Payment methods</a>
                </QuesitonItem>
                <QuesitonItem question='How to cancel an order?'>
                Please request cancellation by email <a href="mailto:vip@kalimera.vip" style={{color: '#0755B1', textDecoration: 'underline'}}>vip@kalimera.vip</a>
                </QuesitonItem>
              </div>
            </div>
          </MobileHiddenBlock>
          }

        </div>
      </div>
      {
      language ? language === 'ru' ?
      <div className={styles.sectionMoreServicesWrapper}>
        <div className="container">
          <section className={styles.sectionMoreServices}>
            <QuoteComponent />
            <div className={styles.sectionMoreServicesTitleWrapper}>
              <SectionTitle mainWords='Больше услуг на Кипре?' otherText='- Да, пожалуйста.'/>
            </div>
            <div className={styles.serviceCards}>
              <ServiceCard title="Аренда автомобиля или яхты" descr='Возьмите от путешествия все самое лучшее.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com'/>
              <ServiceCard title="Трансферы по всему Кипру" descr='Поездка как искусство. Только для исключительных лиц.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://online.orpheus-travel.com/'/>
              <ServiceCard title="Отели и VIP-жилье" descr='Роскошный отдых. Дом вдали от дома.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
            </div>
          </section>
        </div>
      </div> :
      <div className={styles.sectionMoreServicesWrapper}>
      <div className="container">
        <section className={styles.sectionMoreServices}>
          <QuoteComponent />
          <div className={styles.sectionMoreServicesTitleWrapper}>
            <SectionTitle mainWords='More services in Cyprus?' otherText='- Yes, please'/>
          </div>
          <div className={styles.serviceCards}>
            <ServiceCard title="Rent a car or yacht" descr='Take the best from your trip.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com/'/>
            <ServiceCard title="Transfers all over Cyprus" descr='Transfer as an art. For exeptional persons only.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://online.orpheus-travel.com/'/>
            <ServiceCard title="Hotels & VIP Accommodation" descr='Luxury stay. Home away from home.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
          </div>
        </section>
      </div>
      </div> :
        <div className={styles.sectionMoreServicesWrapper}>
        <div className="container">
          <section className={styles.sectionMoreServices}>
            <QuoteComponent />
            <div className={styles.sectionMoreServicesTitleWrapper}>
              <SectionTitle mainWords='More services in Cyprus?' otherText='- Yes, please'/>
            </div>
            <div className={styles.serviceCards}>
              <ServiceCard title="Rent a car or yacht" descr='Take the best from your trip.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com/'/>
              <ServiceCard title="Transfers all over Cyprus" descr='Transfer as an art. For exeptional persons only.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://www.orpheus-travel.com/en/'/>
              <ServiceCard title="Hotels & VIP Accommodation" descr='Luxury stay. Home away from home.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
            </div>
          </section>
        </div>
      </div>
      }
      {
      language ? language === 'ru' ?
      <div className={styles.mobileHiddenBlocksWrapper}>
        <div className="container">
          <MobileHiddenBlock title={<SectionTitle mainWords='Дополнительные услуги' otherText='на Кипре?'/>}>
          <section className={styles.sectionMoreServices}>
            <QuoteComponent />
            <div className={styles.serviceCards}>
              <ServiceCard title="Аренда автомобиля или яхты" descr='Возьмите от путешествия все самое лучшее.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com/'/>
              <ServiceCard title="Трансферы по всему Кипру" descr='Поездка как искусство. Только для исключительных лиц.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://www.orpheus-travel.com/en/'/>
              <ServiceCard title="Отели и VIP-жилье" descr='Роскошный отдых. Дом вдали от дома.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
            </div>
          </section>
          </MobileHiddenBlock>
        </div>
      </div> :
      <div className={styles.mobileHiddenBlocksWrapper}>
      <div className="container">
        <MobileHiddenBlock title={<SectionTitle mainWords='More services' otherText='in Cyprus?'/>}>
        <section className={styles.sectionMoreServices}>
          <QuoteComponent />
          <div className={styles.serviceCards}>
            <ServiceCard title="Rent a car or yacht" descr='Take the best from your trip.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com'/>
            <ServiceCard title="Transfers all over Cyprus" descr='Transfer as an art. For exeptional persons only.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://www.orpheus-travel.com/'/>
            <ServiceCard title="Hotels & VIP Accommodation" descr='Luxury stay. Home away from home.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
          </div>
        </section>
        </MobileHiddenBlock>
      </div>
      </div> :
        <div className={styles.mobileHiddenBlocksWrapper}>
        <div className="container">
          <MobileHiddenBlock title={<SectionTitle mainWords='More services' otherText='in Cyprus?'/>}>
          <section className={styles.sectionMoreServices}>
            <QuoteComponent />
            <div className={styles.serviceCards}>
              <ServiceCard title="Rent a car or yacht" descr='Take the best from your trip.' image={ServiceCardFirst} linkName='Autolife' href='https://www.autoliferent.com'/>
              <ServiceCard title="Transfers all over Cyprus" descr='Transfer as an art. For exeptional persons only.' image={ServiceCardSecond} linkName='Orpheus travel' href='https://www.orpheus-travel.com/'/>
              <ServiceCard title="Hotels & VIP Accommodation" descr='Luxury stay. Home away from home.' image={ServiceCardThird} linkName='Relux estates' href='https://reluxestates.com/'/>
            </div>
          </section>
          </MobileHiddenBlock>
        </div>
      </div>
      }

    </main>
  )
}

export default HomeScreen
