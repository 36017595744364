import axios from "axios";

const serverUrl = 'https://server.kalimera.vip';
// const serverUrl = 'http://dev.kalimera.vip:282';

// const serverUrl = 'http://localhost:5500';

// const serverUrl = 'http://49.13.249.162:5500';

const api = axios.create({
    baseURL: `${serverUrl}/api`
});

export {
    api, serverUrl
};
