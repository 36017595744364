import React from 'react'
import styles from './pricingTable.module.scss';

const PricingTable = () => {
    const language = localStorage.getItem('language');
    return (
        <div className={styles.pricingTable}>
            {
                language ? language === 'ru' ?
                        <div className={styles.tableHeader}>
                            <div className={styles.tableHeaderItem}>Взрослый</div>
                            <div className={styles.tableHeaderItem}>Ребенок, 2-12 лет</div>
                            <div className={styles.tableHeaderItem}>Младенец, до 2 лет</div>
                        </div> :
                        <div className={styles.tableHeader}>
                            <div className={styles.tableHeaderItem}>Adult</div>
                            <div className={styles.tableHeaderItem}>Child, 2-12 years</div>
                            <div className={styles.tableHeaderItem}>Infant, under 2 years</div>
                        </div> :
                    <div className={styles.tableHeader}>
                        <div className={styles.tableHeaderItem}>Adult</div>
                        <div className={styles.tableHeaderItem}>Child, 2-12 years</div>
                        <div className={styles.tableHeaderItem}>Infant, under 2 years</div>
                    </div>
            }
            {
                language ? language === 'ru' ?
                        <div className="tableBody">
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Аэропорт Ларнаки</div>
                                <div className={styles.tableBodyItemValue}>130 €</div>
                                <div className={styles.tableBodyItemValue}>55 €</div>
                                <div className={styles.tableBodyItemValue}>бесплатно</div>
                            </div>
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Аэропорт Пафоса</div>
                                <div className={styles.tableBodyItemValue}>130 €</div>
                                <div className={styles.tableBodyItemValue}>55 €</div>
                                <div className={styles.tableBodyItemValue}>бесплатно</div>
                            </div>
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Доступ в Vip-зал</div>
                                <div className={styles.tableBodyItemValue}>60 €</div>
                                <div className={styles.tableBodyItemValue}>40 €</div>
                                <div className={styles.tableBodyItemValue}>бесплатно</div>
                            </div>
                        </div> :
                        <div className="tableBody">
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Larnaca Airport</div>
                                <div className={styles.tableBodyItemValue}>130 €</div>
                                <div className={styles.tableBodyItemValue}>55 €</div>
                                <div className={styles.tableBodyItemValue}>free</div>
                            </div>
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Paphos Airport</div>
                                <div className={styles.tableBodyItemValue}>130 €</div>
                                <div className={styles.tableBodyItemValue}>55 €</div>
                                <div className={styles.tableBodyItemValue}>free</div>
                            </div>
                            <div className={styles.tableBodyItem}>
                                <div className={styles.tableBodyItemValue}>Vip Lounge Access</div>
                                <div className={styles.tableBodyItemValue}>60 €</div>
                                <div className={styles.tableBodyItemValue}>40 €</div>
                                <div className={styles.tableBodyItemValue}>free</div>
                            </div>
                        </div> :
                    <div className="tableBody">
                        <div className={styles.tableBodyItem}>
                            <div className={styles.tableBodyItemValue}>Larnaca Airport</div>
                            <div className={styles.tableBodyItemValue}>130 €</div>
                            <div className={styles.tableBodyItemValue}>55 €</div>
                            <div className={styles.tableBodyItemValue}>free</div>
                        </div>
                        <div className={styles.tableBodyItem}>
                            <div className={styles.tableBodyItemValue}>Paphos Airport</div>
                            <div className={styles.tableBodyItemValue}>130 €</div>
                            <div className={styles.tableBodyItemValue}>55 €</div>
                            <div className={styles.tableBodyItemValue}>free</div>
                        </div>
                        <div className={styles.tableBodyItem}>
                            <div className={styles.tableBodyItemValue}>Vip Lounge Access</div>
                            <div className={styles.tableBodyItemValue}>60 €</div>
                            <div className={styles.tableBodyItemValue}>40 €</div>
                            <div className={styles.tableBodyItemValue}>free</div>
                        </div>
                    </div>
            }
            {
                language ? language === 'ru' ?
                        <>
                            <p className={styles.tableText}>
                                Младенец до 2 лет: бесплатно.
                            </p>
                            <p className={styles.tableText}>
                                Услуга, заказанная менее чем за 24 часа до вылета: +100% дополнительно. Отмена заказа менее чем за 24 часа до вылета: 100% доплаты.
                            </p>
                        </> :
                        <>
                            <p className={styles.tableText}>
                                Infant under 2 y.o.: free of charge.
                            </p>
                            <p className={styles.tableText}>
                                A service ordered less than 24 hours prior to your flight: +100% extra. Cancellation less than 24 hours prior to your flight: 100% charge.
                            </p>
                        </>:
                    <>
                        <p className={styles.tableText}>
                            Infant under 2 y.o.: free of charge.
                        </p>
                        <p className={styles.tableText}>
                            A service ordered less than 24 hours prior to your flight: +100% extra. Cancellation less than 24 hours prior to your flight: 100% charge.
                        </p>
                    </>
            }

        </div>
    )
}

export default PricingTable
