import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

interface ILayout {
    children: React.ReactNode
}

const Layout = ({ children }: ILayout) => {
  return (
    <>
        <Header />
        <div className="wrapper">
          { children }
        </div>
        <Footer />
    </>
  )
}

export default Layout