// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_header__A7NEZ {
  padding: 21px 0px;
  position: fixed;
  width: 100%;
  background: var(--yellow);
  z-index: 10;
}

.header_headerContent__gPg-K {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media (max-width: 680px) {
  .header_headerContent__gPg-K {
    align-items: flex-start;
  }
  .header_toggleWrapper__XBcVr {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/header.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,8BAAA;AACJ;;AAEA;EACI;IACI,uBAAA;EACN;EAEE;IACI,aAAA;EAAN;AACF","sourcesContent":[".header{\n    padding: 21px 0px;\n    position: fixed;\n    width: 100%;\n    background: var(--yellow);\n    z-index: 10;\n}\n\n.headerContent{\n    display: flex;\n    align-items: flex-start;\n    justify-content: space-between;\n}\n\n@media(max-width: 680px){\n    .headerContent{\n        align-items: flex-start;\n    }\n\n    .toggleWrapper{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header_header__A7NEZ`,
	"headerContent": `header_headerContent__gPg-K`,
	"toggleWrapper": `header_toggleWrapper__XBcVr`
};
export default ___CSS_LOADER_EXPORT___;
