import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom/client';
import Layout from './components/Layout/Layout';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './styles/globals.scss';

const Book = lazy(() => import('./screens/Book/Book'));
const PaymentSuccess = lazy(() => import('./screens/PaymentSuccess/PaymentSuccess'));
const PaymentError = lazy(() => import('./screens/PaymentError/PaymentError'));
const PaymentMethods = lazy(() => import('./screens/PaymentMethods/PaymentMethods'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/book",
    element: <Suspense fallback={<p>Loading...</p>}><Book /></Suspense>,
  },
  {
    path: "/payment-success",
    element: <Suspense fallback={<p>Loading...</p>}><PaymentSuccess/></Suspense>,
  },
  {
    path: "/payment-error",
    element: <Suspense fallback={<p>Loading...</p>}><PaymentError/></Suspense>,
  },
  {
    path: "/payment-methods",
    element: <Suspense fallback={<p>Loading...</p>}><PaymentMethods/></Suspense>,
  }
]);

root.render(
    <Layout>
      <RouterProvider router={router}/>
    </Layout>
);
