import React from 'react'
import styles from './shedulesListItem.module.scss';

interface IShedulesListItem {
    title: string;
    flightNumber: string;
    carrier: string;
    route: string;
    id: string;
    onSelectHandler: (title: string, flightNumber: string, carrier: string, route: string, id: string) => void;
}

const ShedulesListItem = ({ title, flightNumber, carrier, route, id, onSelectHandler }: IShedulesListItem) => {
  return (
    <li className={styles.shedulesListItem} onClick={() => onSelectHandler(title, flightNumber, carrier, route, id)}>
        <div className={styles.shedulesListItemInfo}>{flightNumber} / {carrier}</div>
        <div className={styles.shedulesListItemRoute}>{route}</div>
    </li>
  )
}

export default ShedulesListItem