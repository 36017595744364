// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileMenuNav_mobileMenuNavMainListLink__vcQsO {
  color: var(--white);
  font-family: "Sen";
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  display: block;
  transition: color 0.4s;
}
.mobileMenuNav_mobileMenuNavMainListLink__vcQsO:hover {
  color: var(--yellow);
}

.mobileMenuNav_mobileMenuSubNavList__Ee6FN:nth-last-of-type(1) {
  margin-top: 25px;
}

.mobileMenuNav_mobileMenuNavMainList__l3Obk {
  margin-bottom: 40px;
  margin-top: 40px;
}

.mobileMenuNav_mobileMenuSubNavListLink__GbSAg {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  transition: color 0.4s;
  text-transform: uppercase;
  display: block;
}
.mobileMenuNav_mobileMenuSubNavListLink__GbSAg:hover {
  color: var(--yellow);
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMenuNav/mobileMenuNav.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,sBAAA;AACJ;AACI;EACI,oBAAA;AACR;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;EACA,cAAA;AAAJ;AAEI;EACI,oBAAA;AAAR","sourcesContent":[".mobileMenuNavMainListLink{\n    color: var(--white);\n    font-family: 'Sen';\n    font-size: 28px;\n    font-weight: 700;\n    line-height: 40px;\n    display: block;\n    transition: color 0.4s;\n\n    &:hover{\n        color: var(--yellow);\n    }\n}\n\n.mobileMenuSubNavList:nth-last-of-type(1){\n    margin-top: 25px;\n}\n\n.mobileMenuNavMainList{\n    margin-bottom: 40px;\n    margin-top: 40px;\n}\n\n.mobileMenuSubNavListLink{\n    color: var(--white);\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 150%;\n    transition: color 0.4s;\n    text-transform: uppercase;\n    display: block;\n\n    &:hover{\n        color: var(--yellow);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenuNavMainListLink": `mobileMenuNav_mobileMenuNavMainListLink__vcQsO`,
	"mobileMenuSubNavList": `mobileMenuNav_mobileMenuSubNavList__Ee6FN`,
	"mobileMenuNavMainList": `mobileMenuNav_mobileMenuNavMainList__l3Obk`,
	"mobileMenuSubNavListLink": `mobileMenuNav_mobileMenuSubNavListLink__GbSAg`
};
export default ___CSS_LOADER_EXPORT___;
