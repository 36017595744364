// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.serviceItem_serviceItem__m9een {
  position: relative;
  padding-left: 23px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  max-width: 400px;
}
.serviceItem_serviceItem__m9een::not(:first-of-type) {
  margin-top: 10px;
}
.serviceItem_serviceItem__m9een::before {
  content: "";
  width: 15px;
  height: 3px;
  background: var(--dark-pink);
  position: absolute;
  top: 10px;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ServiceItem/serviceItem.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,WAAA;EACA,WAAA;EACA,WAAA;EACA,4BAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;AAAR","sourcesContent":[".serviceItem{\n    position: relative;\n    padding-left: 23px;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 150%;\n    max-width: 400px;\n\n    &::not(:first-of-type){\n        margin-top: 10px;\n    }\n\n    &::before{\n        content: '';\n        width: 15px;\n        height: 3px;\n        background: var(--dark-pink);\n        position: absolute;\n        top: 10px;\n        left: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceItem": `serviceItem_serviceItem__m9een`
};
export default ___CSS_LOADER_EXPORT___;
