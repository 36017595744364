import styles from './footerNav.module.scss'

interface IFooterNav{
    navigation: {id: number, name: string, href: string}[]
}

const FooterNav = ({ navigation }: IFooterNav) => {
  return (
    <nav className={styles.footerNav}>
        <ul>
        {navigation.map((el) => (
            <li className={styles.footerNavListItem} key={el.id}>
                <a href={el.href} className={styles.footerNavListLink}>{el.name}</a>
            </li> 
        ))}
        </ul>
    </nav>
  )
}

export default FooterNav