import Arrow from '../../images/svg/link-arrow-right.svg';
import styles from './serviceCard.module.scss';

interface IServiceCard{
    title: string;
    descr: string;
    image: string;
    linkName: string;
    href: string;
}

const ServiceCard = (props: IServiceCard) => {
  return (
    <div className={styles.serviceCard}>
        <img loading='lazy' src={props.image} alt={props.title} width={128} height={178} className={styles.serviceCardImage} />
        <div className={styles.serviceCardMain}>
            <h3 className={styles.serviceCardName}>{props.title}</h3>
            <p className={styles.serviceCardDescr}>{props.descr}</p>
            <div className={styles.serviceCardLinkWrapper}>
                <a href={props.href} className={styles.serviceCardLink} target='_blank' rel='noreferrer'>
                    {props.linkName}
                    <img loading='lazy' src={Arrow} alt="Go" width={26} height={28} />
                </a>
            </div>
        </div>
    </div>
  )
}

export default ServiceCard