// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageToggle_languageToggle__pUJUh {
  position: relative;
}

.languageToggle_languageVariants__MA5\\+p {
  position: absolute;
  top: 20px;
  left: 0;
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}

.languageToggle_languageVariantsActive__wupvm {
  position: absolute;
  top: 20px;
  left: 0;
  background: var(--white);
  padding: 20px;
  border-radius: 10px;
  opacity: 1;
  visibility: visible;
  transition: 0.4s;
}

.languageToggle_laguageMain__TEhRQ {
  font-family: "Red Hat Display";
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

.languageToggle_languageVariantsItem__-T\\+GV {
  font-family: "Red Hat Display";
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
.languageToggle_languageVariantsItem__-T\\+GV:not(:first-child) {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/LanguageToggle/languageToggle.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,OAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,SAAA;EACA,OAAA;EACA,wBAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;EACA,mBAAA;EACA,gBAAA;AACJ;;AAEA;EACI,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AACJ;;AAEA;EACI,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACI;EACI,gBAAA;AACR","sourcesContent":[".languageToggle{\r\n    position: relative;\r\n}\r\n\r\n.languageVariants{\r\n    position: absolute;\r\n    top: 20px;\r\n    left: 0;\r\n    background: var(--white);\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    opacity: 0;\r\n    visibility: hidden;\r\n    transition: 0.4s;\r\n}\r\n\r\n.languageVariantsActive{\r\n    position: absolute;\r\n    top: 20px;\r\n    left: 0;\r\n    background: var(--white);\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    opacity: 1;\r\n    visibility: visible;\r\n    transition: 0.4s;\r\n}\r\n\r\n.laguageMain{\r\n    font-family: 'Red Hat Display';\r\n    font-size: 12px;\r\n    font-weight: 700;\r\n    line-height: 20px;\r\n    cursor: pointer;\r\n}\r\n\r\n.languageVariantsItem{\r\n    font-family: 'Red Hat Display';\r\n    font-size: 14px;\r\n    font-weight: 700;\r\n    line-height: 20px;\r\n    cursor: pointer;\r\n\r\n    &:not(:first-child){\r\n        margin-top: 10px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageToggle": `languageToggle_languageToggle__pUJUh`,
	"languageVariants": `languageToggle_languageVariants__MA5+p`,
	"languageVariantsActive": `languageToggle_languageVariantsActive__wupvm`,
	"laguageMain": `languageToggle_laguageMain__TEhRQ`,
	"languageVariantsItem": `languageToggle_languageVariantsItem__-T+GV`
};
export default ___CSS_LOADER_EXPORT___;
