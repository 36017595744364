// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 450px) {
  .logo_logo__ootoj img {
    width: 132px;
    height: 31px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/logo.module.scss"],"names":[],"mappings":"AAAA;EAEQ;IACI,YAAA;IACA,YAAA;EAAV;AACF","sourcesContent":["@media(max-width: 450px){\n    .logo{\n        img{\n            width: 132px;\n            height: 31px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `logo_logo__ootoj`
};
export default ___CSS_LOADER_EXPORT___;
