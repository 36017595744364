// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerContacts_headerContactsSocialsLinksItem__n0\\+Lh {
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.headerContacts_headerContacts__5Dczw {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.headerContacts_headerContactsSocialsLinks__bF-Kz:nth-of-type(3) {
  display: none;
}

.headerContacts_telephoneWrapper__trAW3 a {
  color: var(--braun);
}

@media (max-width: 450px) {
  .headerContacts_headerContactsSocialsLinks__bF-Kz:nth-of-type(2) {
    display: none;
  }
  .headerContacts_headerContactsSocialsLinks__bF-Kz:nth-of-type(3) {
    display: flex;
    margin-right: 4px;
    transform: translateY(1px);
  }
  .headerContacts_headerContactsSocialsLinks__bF-Kz:nth-of-type(3) a:not(:first-child) {
    margin-left: 4px;
  }
  .headerContacts_headerContacts__5Dczw {
    flex-direction: row-reverse;
    align-items: center;
  }
}
@media (max-width: 350px) {
  .headerContacts_headerContacts__5Dczw {
    flex-direction: column;
    align-items: flex-end;
  }
  .headerContacts_headerContactsSocialsLinks__bF-Kz:nth-of-type(3) {
    margin-right: 0px;
    transform: translateY(0px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderContacts/headerContacts.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;EACA,qBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAGI;EACI,mBAAA;AAAR;;AAIA;EACI;IACI,aAAA;EADN;EAIE;IACI,aAAA;IACA,iBAAA;IACA,0BAAA;EAFN;EAIM;IACI,gBAAA;EAFV;EAME;IACI,2BAAA;IACA,mBAAA;EAJN;AACF;AAOA;EACI;IACI,sBAAA;IACA,qBAAA;EALN;EAQE;IACI,iBAAA;IACA,0BAAA;EANN;AACF","sourcesContent":[".headerContactsSocialsLinksItem{\n    font-size: 12px;\n    font-weight: 700;\n    line-height: 18px;\n    text-transform: uppercase;\n}\n\n.headerContacts{\n    display: flex;\n    align-items: flex-end;\n    flex-direction: column;\n}\n\n.headerContactsSocialsLinks:nth-of-type(3){\n    display: none;\n}\n\n.telephoneWrapper{\n    a{\n        color: var(--braun);\n    }\n}\n\n@media(max-width: 450px){\n    .headerContactsSocialsLinks:nth-of-type(2){\n        display: none;\n    }\n\n    .headerContactsSocialsLinks:nth-of-type(3){\n        display: flex;\n        margin-right: 4px;\n        transform: translateY(1px);\n\n        a:not(:first-child){\n            margin-left: 4px;\n        }\n    }\n\n    .headerContacts{\n        flex-direction: row-reverse;\n        align-items: center;\n    }\n}\n\n@media(max-width: 350px){\n    .headerContacts{\n        flex-direction: column;\n        align-items: flex-end;\n    }\n\n    .headerContactsSocialsLinks:nth-of-type(3){\n        margin-right: 0px;\n        transform: translateY(0px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerContactsSocialsLinksItem": `headerContacts_headerContactsSocialsLinksItem__n0+Lh`,
	"headerContacts": `headerContacts_headerContacts__5Dczw`,
	"headerContactsSocialsLinks": `headerContacts_headerContactsSocialsLinks__bF-Kz`,
	"telephoneWrapper": `headerContacts_telephoneWrapper__trAW3`
};
export default ___CSS_LOADER_EXPORT___;
