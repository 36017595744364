// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerNav_footerNav__YZqqJ ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.footerNav_footerNav__YZqqJ:not(:first-of-type) {
  margin-top: 30px;
}
.footerNav_footerNav__YZqqJ:not(:first-of-type) ul {
  display: flex;
}
.footerNav_footerNav__YZqqJ:not(:first-of-type) ul li:not(:first-child) {
  margin-left: 4px;
  margin-top: 0px;
}

.footerNav_footerNavListLink__c0B61 {
  color: var(--braun);
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  text-decoration-line: underline;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .footerNav_footerNav__YZqqJ:not(:first-of-type) ul li:not(:first-child) {
    margin-left: 0px;
    margin-top: 8px;
  }
  .footerNav_footerNav__YZqqJ {
    margin-top: 32px;
  }
  .footerNav_footerNav__YZqqJ ul {
    align-items: flex-start;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/FooterNav/footerNav.module.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AAAR;AAGI;EACK,gBAAA;AADT;AAGQ;EACI,aAAA;AADZ;AAGY;EACI,gBAAA;EACA,eAAA;AADhB;;AAOA;EACI,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,+BAAA;EACA,yBAAA;AAJJ;;AAOA;EAGY;IACI,gBAAA;IACA,eAAA;EANd;EAWE;IACI,gBAAA;EATN;EAWM;IACI,uBAAA;EATV;AACF","sourcesContent":[".footerNav{\n    & ul{\n        display: flex;\n        flex-direction: column;\n        align-items: flex-end;\n    }\n\n    &:not(:first-of-type){\n         margin-top: 30px;\n\n        & ul{\n            display: flex;\n    \n            & li:not(:first-child){\n                margin-left: 4px;\n                margin-top: 0px;\n            }\n        }\n    }\n}\n\n.footerNavListLink{\n    color: var(--braun);\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 130%;\n    text-decoration-line: underline;\n    text-transform: uppercase;\n}\n\n@media(max-width: 900px){\n    .footerNav:not(:first-of-type){\n        & ul{\n            & li:not(:first-child){\n                margin-left: 0px;\n                margin-top: 8px;\n            }\n        }\n    }\n\n    .footerNav{\n        margin-top: 32px;\n        \n        & ul{\n            align-items: flex-start;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerNav": `footerNav_footerNav__YZqqJ`,
	"footerNavListLink": `footerNav_footerNavListLink__c0B61`
};
export default ___CSS_LOADER_EXPORT___;
