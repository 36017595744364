import { useState } from 'react'
import styles from './languageToggle.module.scss'

const LanguageToggle = () => {

    const [isOpened, setIsOpened] = useState(false);

    const setLanguage = (language: string) => {
        localStorage.setItem('language', language);
        setIsOpened(false)
        window.location.reload();
    }

  return (
    <div className={styles.languageToggle}>
        <div className={styles.laguageMain} onClick={() => setIsOpened((prev) => !prev)}>LANGUAGE</div>
        <div className={isOpened ? styles.languageVariantsActive : styles.languageVariants}>
            <div className={styles.languageVariantsItem} onClick={() => setLanguage('en')}>English</div>
            <div className={styles.languageVariantsItem} onClick={() => setLanguage('ru')}>Russian</div>
        </div>
    </div>
  )
}

export default LanguageToggle