import React, { useState } from 'react';
import Arrow from '../../images/svg/pink-arrow-down.svg';
import styles from './mobileHiddenBlock.module.scss';

interface IMobileHiddenBlock{
    title: React.ReactNode;
    children: React.ReactNode;
}

const MobileHiddenBlock = ({ title, children }: IMobileHiddenBlock) => {

    const [isActive, setIsActive] = useState(false);

  return (
    <div className={isActive ? styles.mobileHiddenBlockActive : styles.mobileHiddenBlock}>
        <div className={styles.mobileHiddenBlockMain} onClick={() => setIsActive((prev) => !prev)}>
            {title}
            <img loading='lazy' src={Arrow} alt="Toggle" width={24} height={13} />
        </div>
        <div className={styles.mobileHiddenBlockContent}>
            {children}
        </div>
    </div>
  )
}

export default MobileHiddenBlock