import Telephone from '../Telephone/Telephone';
import Email from '../Email/Email';
import Logo from '../Logo/Logo';
import PaymentMethodLogo from '../PaymentMethodLogo/PaymentMethodLogo';

import Telegram from '../../images/svg/telegram.svg'
import WhatsUp from '../../images/svg/whatsup.svg'
import Facebook from '../../images/svg/facebook.svg'
import Instagram from '../../images/svg/inst.svg'
import PoweredBy from '../../images/svg/poweredBy.svg'

import Mastercard from '../../images/payment/mastercard.svg'
import Visa from '../../images/payment/visa.svg'
import Revolut from '../../images/payment/revolut.svg'
import PayPal from '../../images/payment/paypal.svg'
import Swift from '../../images/payment/swift.svg'
import Bitcoin from '../../images/payment/bitcoin.svg'

import styles from './footer.module.scss';
import FooterNav from './FooterNav/FooterNav';

const language = localStorage.getItem('language');

const navigation =
  language ? language === 'ru' ?
  [
    [
      {id: 1, name: 'Главная страница', href: '/'},
      {id: 2, name: 'Способы оплаты', href: '/payment-methods'},
    ],
    [
      {id: 3, name: 'Политика конфиденциальности', href: '/'},
      {id: 4, name: 'Условия предоставления услуг', href: '/'},
    ],
    [
      {id: 6, name: 'Сайт аэропорта Ларнаки', href: 'https://www.larnaca-airport.com/'},
      {id: 7, name: 'Сайт аэропорта Пафоса', href: 'https://www.airport-paphos.com/'},
    ],
    ] :
    [
      [
        {id: 1, name: 'Main page', href: '/'},
        {id: 2, name: 'Payment methods', href: '/payment-methods'},
      ],
      [
        {id: 3, name: 'Privacy policy', href: '/'},
        {id: 4, name: 'Terms of Service', href: '/'},
      ],
      [
        {id: 6, name: 'Larnaca Airport website', href: 'https://www.larnaca-airport.com/'},
        {id: 7, name: 'Paphos Airport website', href: 'https://www.airport-paphos.com/'},
      ],
      ] :
      [
        [
          {id: 1, name: 'Main page', href: '/'},
          {id: 2, name: 'Payment methods', href: '/payment-methods'},
        ],
        [
          {id: 3, name: 'Privacy policy', href: '/'},
          {id: 4, name: 'Terms of Service', href: '/'},
        ],
        [
          {id: 6, name: 'Larnaca Airport website', href: 'https://www.larnaca-airport.com/'},
          {id: 7, name: 'Paphos Airport website', href: 'https://www.airport-paphos.com/'},
        ],
        ]


const Footer = () => {
  return(
    <div className="yellowWrapper">
      <div className={styles.footerDesktop}>
        <footer className={styles.footer}>
          <div className="container">
            <div className={styles.footerContent}>
              <div className={styles.firstColumn}>
                <Logo />
                <div className={styles.payment}>
                  <img loading='lazy' src={PoweredBy} alt="Stripe" width={85} height={14} />
                  <div className="paymentMethods">
                    <PaymentMethodLogo Logo={Mastercard}/>
                    <PaymentMethodLogo Logo={Visa}/>
                    <PaymentMethodLogo Logo={Revolut}/>
                    <PaymentMethodLogo Logo={PayPal}/>
                    <PaymentMethodLogo Logo={Swift}/>
                    <PaymentMethodLogo Logo={Bitcoin}/>
                  </div>
              </div>
              </div>
              <div className={styles.secondColumn}>
              <FooterNav navigation={navigation[0]}/>
              <FooterNav navigation={navigation[1]}/>
              </div>
              <div className={styles.thirdColumn}>
              <div className={styles.footerContacts}>
                <div>
                  <div className={styles.footerSocials}>
                    <a href="https://t.me/+35797817779">
                      <img loading='lazy' src={Telegram} alt="Telegram" width={16} height={16} />
                    </a>
                    <a href="https://wa.me/35797817779">
                      <img loading='lazy' src={WhatsUp} alt="WhatsUp" width={16} height={16} />
                    </a>
                  </div>
                  <Telephone />
                </div>
                <Email />
              </div>
              <FooterNav navigation={navigation[2]}/>
              <div className={styles.footerOtherSocials}>
                <div>
                {
                language ? language === 'ru' ?
                'СЛЕДИТЕ ЗА НАМИ':
                'FOLLOW US' :
                'FOLLOW US'
                }

                  <div className={styles.footerSocials}>
                    <a href="">
                      <img loading='lazy' src={Facebook} alt="Facebook" width={16} height={16} />
                    </a>
                    <a href="">
                      <img loading='lazy' src={Instagram} alt="Instagram" width={16} height={16} />
                    </a>
                  </div>
                </div>
                <div className={styles.rights}>© {new Date().getFullYear()} Καλημέρα Meet & Greet. All rights reserved.</div>
              </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <div className={styles.footerMobile}>
        <footer className={styles.footer}>
          <div className="container">
            <div className="footerContent">
              <div className={styles.firstRow}>
                <Logo />
                <div className={styles.footerContacts}>
                <div>
                  <div className={styles.footerSocials}>
                    <a href="https://t.me/+35797817779" target='_blank' rel='noreferrer'>
                      <img loading='lazy' src={Telegram} alt="Telegram" width={16} height={16} />
                    </a>
                    <a href="https://wa.me/35797817779" target='_blank' rel='noreferrer'>
                      <img loading='lazy' src={WhatsUp} alt="WhatsUp" width={16} height={16} />
                    </a>
                  </div>
                  <Telephone />
                </div>
                <Email />
              </div>
              </div>
              <div className={styles.secondRow}>
                <FooterNav navigation={navigation[0]}/>
                <FooterNav navigation={navigation[1]}/>
                <FooterNav navigation={navigation[2]}/>
              </div>
              <div className={styles.thirdRow}>
                <div className={styles.payment}>
                  <img loading='lazy' src={PoweredBy} alt="Stripe" width={85} height={14} />
                  <div className="paymentMethods">
                    <PaymentMethodLogo Logo={Mastercard}/>
                    <PaymentMethodLogo Logo={Visa}/>
                    <PaymentMethodLogo Logo={Revolut}/>
                    <PaymentMethodLogo Logo={PayPal}/>
                    <PaymentMethodLogo Logo={Swift}/>
                    <PaymentMethodLogo Logo={Bitcoin}/>
                  </div>
                </div>
                <div className={styles.footerOtherSocials}>
                  <div>
                    FOLLOW US
                    <div className={styles.footerSocials}>
                      <a href="https://www.facebook.com/kalimera.vip/" target='_blank' rel='noreferrer'>
                        <img loading='lazy' src={Facebook} alt="Facebook" width={16} height={16} />
                      </a>
                      <a href="https://www.instagram.com/kalimera.vip/" target='_blank' rel='noreferrer'>
                        <img loading='lazy' src={Instagram} alt="Instagram" width={16} height={16} />
                      </a>
                    </div>
                  </div>
                  <div className={styles.rights}>© {new Date().getFullYear()} Καλημέρα Meet & Greet. All rights reserved.</div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Footer
