import React from 'react'
import styles from './flightInput.module.scss';

interface IFlightInput {
  value: string;
  disabled: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FlightInput = ({ value, disabled, handleChange }: IFlightInput) => {
  const language = localStorage.getItem('language');
  return(
    language ? language === 'ru' ?
    <input disabled={disabled} className={styles.flightInput} type="text" value={value} placeholder="Ваш номер полета" onChange={(e) => handleChange(e)}/>:
    <input disabled={disabled} className={styles.flightInput} type="text" value={value} placeholder="Your Flight #" onChange={(e) => handleChange(e)}/> :
    <input disabled={disabled} className={styles.flightInput} type="text" value={value} placeholder="Your Flight #" onChange={(e) => handleChange(e)}/>
  ) 
}

export default FlightInput