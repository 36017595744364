import { Dispatch, SetStateAction } from 'react'
import MobileMenuNav from '../MobileMenuNav/MobileMenuNav';
import MobileContacts from '../MobileContacts/MobileContacts';
import CloseMobileMenuButton from '../CloseMobileMenuButton/CloseMobileMenuButton';
import LanguageToggle from '../LanguageToggle/LanguageToggle';
import styles from './mobileMenu.module.scss';

interface IMobileMenu {
  menuIsOpened: boolean;
  setMenuIsOpened: Dispatch<SetStateAction<boolean>>
}

const MobileMenu = ({ menuIsOpened, setMenuIsOpened }: IMobileMenu) => {
  return (
    <div className={menuIsOpened ? styles.mobileMenuWrapperActive : styles.mobileMenuWrapper}>
        <CloseMobileMenuButton setMenuIsOpened={setMenuIsOpened}/>
        <MobileMenuNav />
        <div className={styles.toggleWrapper}>
            <LanguageToggle />
        </div>
        <MobileContacts />
    </div>
  )
}

export default MobileMenu