import React, { useEffect, useMemo, useState, useRef, Dispatch, SetStateAction } from 'react'
import FlightInput from '../FlightInput/FlightInput';
import FlightInputLabel from '../FlightInputLabel/FlightInputLabel';
// @ts-nocheck
// @ts-ignore
import DatePicker from "react-datepicker";
import Airplane from '../../images/svg/airplane.svg';
import Calendar from '../../images/svg/calendar.svg';
import Arrow from '../../images/svg/mobile-arrow-right.svg';
// @ts-nocheck
// @ts-ignore
import { debounce } from "lodash-es";
import { useLatest } from '../../hooks/use-latest';
import styles from './flightSearch.module.scss'
import 'react-datepicker/dist/react-datepicker.css'
import { getShedules, getSheduleDates } from '../../service/schedulesService';
import { ISchedule } from '../../types/Schedule';
import ShedulesListItem from '../ShedulesList/ShedulesListItem';
import { ISelectedSchedule } from '../../types/SelectedSchedule';
import { IAvailableDate } from '../../types/AvailableDate';
import { IFlight } from '../../types/FirstStep/types';
import { IPricing } from '../../types/Pricing';
import { generateServices } from '../../utils/generateServices';

const makeDebouncedHook = (debounceFn: any) => {

  return function useDebounce(cb: any, ms: any) {
    const latestCb = useLatest(cb);

    const debouncedFn = useMemo(
      () =>
        debounceFn((...args: any) => {
          latestCb.current(...args);
        }, ms),
      [ms, latestCb]
    );

    useEffect(() => () => debouncedFn.cancel(), [debouncedFn]);

    return debouncedFn;
  };
}

interface IFlightSearch {
  showLink: boolean;
  flight: 'firstFlight' | 'secondFlight';
  setFlight?: Dispatch<SetStateAction<IFlight | null>>
  pricing?: IPricing | IPricing[]
}

const useDebounce = makeDebouncedHook(debounce);

const FlightSearch = ({ pricing, showLink, flight, setFlight }: IFlightSearch) => {
  const language = localStorage.getItem('language');
  const searchBlock = useRef(null);

  const [calendarIsOpened, setCalendarIsOpened] = useState(false);
  const [schedules, setSchedules] = useState<null | ISchedule[]>(null);
  const [renderSchedules, setRenderSchedules] = useState<null | ISchedule[]>(null);
  const [flightNumber, setFlightNumber] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [schedulesListIsOpened, setSchedulesListIsOpened] = useState(false);

  const [selectedSchedule, setSelectedSchedule] = useState<null | ISelectedSchedule>(null);

  const [availableDates, setAvailableDates] = useState<null | IAvailableDate[]>(null);

  const onToggleCalendarHandler = () => schedules &&  schedules.length > 0 && setCalendarIsOpened((prev) => !prev);

  const makeRequest = useDebounce(() => {
    if(flightNumber !== ''){
      setIsError(false);
    setIsLoading(true);
    getShedules(flightNumber)
    .then((response) => {
      const uniqSchedules: any[] = [];

      response.forEach((el) => {
        if(!uniqSchedules.find((item) => item.number === el.number)){
          uniqSchedules.push(el)
        }
      })

      setRenderSchedules(uniqSchedules);
      setSchedules(response);

      setIsLoading(false);
      setSchedulesListIsOpened(true);
    })
    .catch(() => {
      setIsError(true)
      setIsLoading(false)
    })
    }
  }, 2000);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if(value !== flightNumber){
      setFlightNumber(value);
    }else{
      return
    }

    if(value !== ''){
      makeRequest(value);
      setSchedulesListIsOpened(false);
    }
  };

  const onSelectScheduleHandler = (title: string, flightNumber: string, carrier: string, route: string, id: string) => {
    setSelectedSchedule({
      title,
      flightNumber,
      carrier,
      route,
      id,
      departure: null,
      arrival: null,
    });

    const equialFlights = schedules!.filter((el) => el.number === flightNumber);
    console.log(equialFlights)
    const flightDates: any = equialFlights.map((el) => {
      if(el.departure){
        return new Date(el.departure).toISOString().split('T')[0]
      }else if(el.arrival){
        return new Date(el.arrival).toISOString().split('T')[0]
      }
    })

    getSheduleDates(equialFlights[0].uid, flightDates)
    .then((response) => setAvailableDates(response))

    setSchedulesListIsOpened(false);
  };

  const onSelectDepartureDate = (date: Date | null) => {
    if(date){

      if(Number(String(date).split('-')[2]) < Number(new Date().toISOString().split('T')[0].split('-')[2])){
        return
      }

      const copy = {...selectedSchedule!};
      console.log(availableDates)
      const sheduleDates = availableDates?.find((el) => el.departureDate.includes(String(new Date(date).toLocaleDateString('ru-RU')).split('.').reverse().join('-')));
      console.log({...copy, departure: sheduleDates?.departureDate, arrival: sheduleDates?.arrivalDate}, sheduleDates, date)
      setSelectedSchedule({...copy, departure: sheduleDates?.departureDate, arrival: sheduleDates?.arrivalDate})
    }
    setCalendarIsOpened(false);
  };

  const firstFlightBookHandler = () => {
    if(selectedSchedule){
      sessionStorage.setItem('firstFlight', JSON.stringify({
        title: selectedSchedule.title,
        departure: selectedSchedule.route.split(' ')[0],
        departureDate: selectedSchedule.departure,
        arrival: selectedSchedule.route.split(' ')[2],
        arrivalDate: selectedSchedule.arrival,
        flightNumber: selectedSchedule.flightNumber,
        company: selectedSchedule.carrier,
        arrivalTime: ''
      }))

      window.location.href = '/book'
    }
  };

  const secondFlightBookHandler = () => {
    if(selectedSchedule && pricing){
      sessionStorage.setItem('secondFlight', JSON.stringify({
        title: selectedSchedule.title,
        departure: selectedSchedule.route.split(' ')[0],
        departureDate: selectedSchedule.departure,
        arrival: selectedSchedule.route.split(' ')[2],
        arrivalDate: selectedSchedule.arrival,
        flightNumber: selectedSchedule.flightNumber,
        company: selectedSchedule.carrier,
        arrivalTime: ''
      }))

      setFlight && setFlight(generateServices(JSON.stringify({
        title: selectedSchedule.title,
        departure: selectedSchedule.route.split(' ')[0],
        departureDate: selectedSchedule.departure,
        arrival: selectedSchedule.route.split(' ')[2],
        arrivalDate: selectedSchedule.arrival,
        flightNumber: selectedSchedule.flightNumber,
        company: selectedSchedule.carrier,
        arrivalTime: ''
      }), (pricing as IPricing[])[selectedSchedule.route.split(' ')[2] === 'Larnaka' || selectedSchedule.route.split(' ')[0] === 'Larnaka' ? (pricing as IPricing[]).findIndex((el) => el.airport === 'larnaca') : (pricing as IPricing[]).findIndex((el) => el.airport === 'paphos')]))

      setTimeout(() => {
        document.querySelector('#secondFlight')!.scrollIntoView({behavior: "smooth"})
      }, 1000)
    }
  }

  return (
    <div className={styles.flightSearchWrapper} ref={searchBlock}>
        <div className={styles.flightSearchInputs}>
          <div onClick={() => {setSchedulesListIsOpened(true); setSelectedSchedule(null)}}>
              {
              language ? language === 'ru' ?
              <FlightInputLabel label={selectedSchedule ? `${selectedSchedule.route}` : 'Например: AE1210'} htmlFor='flightNumber' icon={Airplane} alt='Airplane' /> :
              <FlightInputLabel label={selectedSchedule ? `${selectedSchedule.route}` : 'Example: AE1210'} htmlFor='flightNumber' icon={Airplane} alt='Airplane' /> :
              <FlightInputLabel label={selectedSchedule ? `${selectedSchedule.route}` : 'Example: AE1210'} htmlFor='flightNumber' icon={Airplane} alt='Airplane' />
              }
            <FlightInput disabled={isLoading} value={selectedSchedule ? `${selectedSchedule.flightNumber.replaceAll(' ', '')}, ${selectedSchedule.carrier.replaceAll(' ', '')}` : flightNumber.replaceAll(' ', '')} handleChange={handleChange}/>
          </div>
          <div>
            {
                !isLoading && schedulesListIsOpened && flightNumber !== '' && schedules && schedules.length !== 0
                &&
                <div className={styles.shedulesListWrapper}>
                  {renderSchedules!.map((el) => (
                      <ShedulesListItem title={el.title} key={el.uid} onSelectHandler={onSelectScheduleHandler} flightNumber={el.number} carrier={el.carrier.title} route={el.title} id={el.uid}/>
                  ))}
                </div>
            }
            {
                isError
                &&
                <div className={styles.shedulesListWrapper} style={{overflow: 'hidden'}}>
                  <p style={{marginLeft: '24px'}}>Error</p>
                </div>
            }
            {
                isLoading
                &&
                <>
                  {
                    language ? language === 'ru' ?
                            <div className={styles.shedulesListWrapper} style={{overflow: 'hidden'}}>
                              <p style={{marginLeft: '24px'}}>Получаем данные...</p>
                            </div> :
                            <div className={styles.shedulesListWrapper} style={{overflow: 'hidden'}}>
                              <p style={{marginLeft: '24px'}}>Fetching data...</p>
                            </div> :
                        <div className={styles.shedulesListWrapper} style={{overflow: 'hidden'}}>
                          <p style={{marginLeft: '24px'}}>Fetching data...</p>
                        </div>
                  }
                </>

            }
            {
                !isLoading && flightNumber !== '' && schedules && schedules.length === 0
                &&
                <div className={styles.shedulesListWrapper} style={{overflow: 'hidden'}}>
                  <p style={{marginLeft: '24px'}}>No data found</p>
                </div>
            }
          </div>
          <div>
            {
              language ? language === 'ru' ?
                      <div onClick={onToggleCalendarHandler}>
                        <FlightInputLabel label={selectedSchedule?.departure ? 'Дата отправления' : 'Выберите дату'} htmlFor='flightDate' icon={Calendar} alt='Calendar' />
                        <button className={styles.datePickerTrigger}>{selectedSchedule?.departure ? `${selectedSchedule!.departure!.slice(0, 10)}, ${selectedSchedule!.departure!.slice(10).split('.')[0]}`  : 'Дата отправления'}</button>
                      </div> :
                      <div onClick={onToggleCalendarHandler}>
                        <FlightInputLabel label={selectedSchedule?.departure ? 'Departure Date' : 'Choose Date'} htmlFor='flightDate' icon={Calendar} alt='Calendar' />
                        <button className={styles.datePickerTrigger}>{selectedSchedule?.departure ? `${selectedSchedule!.departure!.slice(0, 10)}, ${selectedSchedule!.departure!.slice(10).split('.')[0]}`  : 'Departure Date'}</button>
                      </div> :
                  <div onClick={onToggleCalendarHandler}>
                    <FlightInputLabel label={selectedSchedule?.departure ? 'Departure Date' : 'Choose Date'} htmlFor='flightDate' icon={Calendar} alt='Calendar' />
                    <button className={styles.datePickerTrigger}>{selectedSchedule?.departure ? `${selectedSchedule!.departure!.slice(0, 10)}, ${selectedSchedule!.departure!.slice(10).split('.')[0]}`  : 'Departure Date'}</button>
                  </div>
            }
          </div>


        </div>
      {
      calendarIsOpened && schedules && selectedSchedule
      &&
      <div className={styles.calendarWrapper}>
        <div className={styles.flightSearchMessage}>
          {
          language ? language === 'ru' ?
          <p>Пожалуйста, выберите дату вылета вашего рейса.</p> :
          <p>Please select Departure Date for your flight.</p>:
          <p>Please select Departure Date for your flight.</p>
          }

        </div>
        <DatePicker
          includeDates={availableDates ? availableDates.map((el) => new Date(el.departureDate)) : []}
          highlightDates={availableDates ? availableDates.map((el) => new Date(el.departureDate)) : []}
          onChange={(date) => onSelectDepartureDate(date)}
          selected={new Date()}
          inline
        />
      </div>
      }
      {
      showLink
      &&
      <button disabled={!selectedSchedule || !selectedSchedule?.departure} className={styles.flightSearchLink} onClick={flight === 'firstFlight' ? firstFlightBookHandler : secondFlightBookHandler}>
        {
        language ? language === 'ru' ?
        'Далее' :
        'Book Now' :
        'Book Now'
        }
        <img loading='lazy' src={Arrow} alt="Book Now" width={26} height={28} />
      </button>
      }
    </div>
  )
}

export default FlightSearch
