// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flightInput_flightInput__h5qgQ {
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  margin-top: 4px;
  width: 204px;
  position: relative;
  z-index: 6;
}
.flightInput_flightInput__h5qgQ::placeholder {
  color: var(--braun);
}
.flightInput_flightInput__h5qgQ:disabled {
  background: var(--white);
}

@media (max-width: 680px) {
  .flightInput_flightInput__h5qgQ {
    width: 335px;
    height: 66px;
    border-radius: 62px;
    padding-left: 27px;
    padding-top: 15px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FlightInput/flightInput.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;AACJ;AACI;EACI,mBAAA;AACR;AAEI;EACI,wBAAA;AAAR;;AAIA;EACI;IACI,YAAA;IACA,YAAA;IACA,mBAAA;IACA,kBAAA;IACA,iBAAA;EADN;AACF","sourcesContent":[".flightInput{\n    border: none;\n    outline: none;\n    font-size: 18px;\n    font-weight: 700;\n    line-height: 17px;\n    margin-top: 4px;\n    width: 204px;\n    position: relative;\n    z-index: 6;\n\n    &::placeholder{\n        color: var(--braun);\n    }\n\n    &:disabled{\n        background: var(--white);\n    }\n}\n\n@media(max-width: 680px){\n    .flightInput{\n        width: 335px;\n        height: 66px;\n        border-radius: 62px;\n        padding-left: 27px;\n        padding-top: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flightInput": `flightInput_flightInput__h5qgQ`
};
export default ___CSS_LOADER_EXPORT___;
