import React from 'react'
import styles from './flightInputLabel.module.scss'

interface IFlightInputLabel {
    label: string;
    htmlFor: string;
    icon: string;
    alt: string;
}

const FlightInputLabel = ({ label, htmlFor, icon, alt }: IFlightInputLabel) => {
  return (
    <label htmlFor={htmlFor} className={styles.flightInputLabel}>
        {label}
        <img loading='lazy' src={icon} alt={alt} width={13} height={13}/>
    </label>
  )
}

export default FlightInputLabel