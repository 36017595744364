import React from 'react'
import styles from './sectionTitle.module.scss';

interface ISectionTitle{
    mainWords: string;
    otherText: string;
}

const SectionTitle = ({ mainWords, otherText }: ISectionTitle) => {
  return <h3 className={styles.sectionTitle}><span>{mainWords}</span> {otherText}</h3>
}

export default SectionTitle