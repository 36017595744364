import React from 'react'
import File from '../../images/png/file-top.png'
import Author from '../../images/png/author.png'
import styles from './quoteComponent.module.scss';

const QuoteComponent = () => {
  const language = localStorage.getItem('language');
  return (
    language ? language === 'ru' ?
    <div className={styles.qouteComponent}>
      <div className={styles.quoteWrapper}>
        <img loading='lazy' src={File} alt="File" width={349} height={65} className={styles.quoteImage} />
        <div className={styles.quote}>
          <blockquote>Ваше время - настоящая драгоценность! Позаботьтесь о нем, встретив Кипр с Kalimera!</blockquote>
          <div className={styles.quoteAuthor}>
            <img loading='lazy' src={Author} alt="Роман Старикович" width={63} height={63} />
            <div className={styles.quoteAuthorMain}>
              <div className={styles.quoteAuthorName}>Роман Старикович</div>
              <div className={styles.quoteAuthorProfession}>
                CEO Καλημέρα
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> :
    <div className={styles.qouteComponent}>
    <div className={styles.quoteWrapper}>
      <img loading='lazy' src={File} alt="File" width={349} height={65} className={styles.quoteImage} />
      <div className={styles.quote}>
        <blockquote>Your time is a real gem! Take care of it by Meeting Cyprus with Kalimera!</blockquote>
        <div className={styles.quoteAuthor}>
          <img loading='lazy' src={Author} alt="Roman Starikovich" width={63} height={63} />
          <div className={styles.quoteAuthorMain}>
            <div className={styles.quoteAuthorName}>Roman Starikovich</div>
            <div className={styles.quoteAuthorProfession}>
              CEO Καλημέρα
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> :
    <div className={styles.qouteComponent}>
    <div className={styles.quoteWrapper}>
      <img loading='lazy' src={File} alt="File" width={349} height={65} className={styles.quoteImage} />
      <div className={styles.quote}>
        <blockquote>Your time is a real gem! Take care of it by Meeting Cyprus with Kalimera!</blockquote>
        <div className={styles.quoteAuthor}>
          <img loading='lazy' src={Author} alt="Roman Starikovich" width={63} height={63} />
          <div className={styles.quoteAuthorMain}>
            <div className={styles.quoteAuthorName}>Roman Starikovich</div>
            <div className={styles.quoteAuthorProfession}>
              CEO Καλημέρα
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default QuoteComponent