import React, { Dispatch, SetStateAction }  from 'react'
import Close from '../../images/svg/close-light.svg'

const CloseMobileMenuButton = ({ setMenuIsOpened }: { setMenuIsOpened: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <button className="closeMobileMenuButton" onClick={() => setMenuIsOpened(false)}>
        <img loading='lazy' src={Close} alt="Close" width={25} height={25} />
    </button>
  )
}

export default CloseMobileMenuButton