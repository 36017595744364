import { useState } from 'react'
import Logo from '../Logo/Logo';
import HeaderContacts from '../HeaderContacts/HeaderContacts';
import MobileMenuButton from '../MobileMenuButton/MobileMenuButton';
import MobileMenu from '../MobileMenu/MobileMenu';
import LanguageToggle from '../LanguageToggle/LanguageToggle'
import styles from './header.module.scss';

const Header = () => {

  const [menuIsOpened, setMenuIsOpened] = useState(false);

  return (
    <div className="yellowWrapper">
      <header className={styles.header}>
      <MobileMenu menuIsOpened={menuIsOpened} setMenuIsOpened={setMenuIsOpened}/>
      <div className="container">
          <div className={styles.headerContent}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <MobileMenuButton setMenuIsOpened={setMenuIsOpened}/>
                <Logo />
              </div>
              <div className={styles.toggleWrapper}>
                <LanguageToggle />
              </div>
              <HeaderContacts />
          </div>
      </div>
      </header>
    </div>
  )
}

export default Header