import { api } from "../api/api"
import { ISchedule } from "../types/Schedule"
import { IAvailableDate } from "../types/AvailableDate";

const getShedules = async (flightNumber: string): Promise<ISchedule[]> => {
    const { data } = await api.get<ISchedule[]>(`/schedules?flightNumber=${flightNumber}`)
    
    return data;
};

const getSheduleDates = async (flightId: string, departureDates: string[]): Promise<IAvailableDate[]> => {
    const { data } = await api.post<IAvailableDate[]>(`/schedules/dates`, { flightId, departureDates })
    
    return data;
};
export {
    getShedules,
    getSheduleDates
}