// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectionTitle_sectionTitle__00f\\+c {
  color: var(--dark-pink);
  font-family: "Sen";
  font-size: 42px;
  font-weight: 400;
  line-height: 42px;
}
.sectionTitle_sectionTitle__00f\\+c span {
  color: var(--dark-pink);
  font-family: "Sen";
  font-size: 42px;
  font-weight: 700;
  line-height: 42px;
  display: block;
}

@media (max-width: 680px) {
  .sectionTitle_sectionTitle__00f\\+c {
    font-size: 32px;
    line-height: 32px;
  }
  .sectionTitle_sectionTitle__00f\\+c span {
    font-size: 32px;
    line-height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SectionTitle/sectionTitle.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACI;EACI,uBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACR;;AAGA;EACI;IACI,eAAA;IACA,iBAAA;EAAN;EAEM;IACI,eAAA;IACA,iBAAA;EAAV;AACF","sourcesContent":[".sectionTitle{\n    color: var(--dark-pink);\n    font-family: 'Sen';\n    font-size: 42px;\n    font-weight: 400;\n    line-height: 42px;\n    \n    span{\n        color: var(--dark-pink);\n        font-family: 'Sen';\n        font-size: 42px;\n        font-weight: 700;\n        line-height: 42px;\n        display: block;\n    }\n}\n\n@media(max-width: 680px){\n    .sectionTitle{\n        font-size: 32px;\n        line-height: 32px;\n        \n        span{\n            font-size: 32px;\n            line-height: 32px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `sectionTitle_sectionTitle__00f+c`
};
export default ___CSS_LOADER_EXPORT___;
