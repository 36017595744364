import { IFlight } from "../types/FirstStep/types";
import { IPricing } from "../types/Pricing";

import AnyCar from '../images/cars/any.png'
import MercedesE from '../images/cars/e.png'
import MercedesS from '../images/cars/s.png'
import Lamborgini from '../images/cars/lambo.png'
import MercedesVito from '../images/cars/vito.png'
import MercedesV from '../images/cars/v.png'
import ToyotaMinibus from '../images/cars/toyota-bus.png'
import MersedesSprinter from '../images/cars/sprinter.png'
import Bus from '../images/cars/bus.png'

const getTransport = (arrival: string, departure: string, pricing: IPricing) => {
  const language = localStorage.getItem('language');

  if(language && language === 'ru'){
    if(arrival === 'Larnaka' || departure === 'Larnaka'){
      return [
        { id: 90, name: 'Мне не нужен транспорт', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Любой свободный автомобиль (до 6 человек)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.larnacaTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (до 4 человек)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.larnacaTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (до 4 человек)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.larnacaTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (up to 7 pax)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.larnacaTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (до 11 человек)', isSelected: false, image: ToyotaMinibus, value: 1, destinations: pricing.larnacaTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (до 19 человек)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.larnacaTransfers[7].destinations},
        { id: 9, name: 'BUS (до 52 человек)', isSelected: false, image: Bus, value: 1, destinations: pricing.larnacaTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (до 1 человека)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.larnacaTransfers[9].destinations},
      ]
    }else if(arrival === 'Pafos' || departure === 'Pafos'){
      return [
        { id: 90, name: 'Мне не нужен транспорт', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Любой свободный автомобиль (до 6 человек)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.paphosTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (до 4 человек)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.paphosTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (до 4 человек)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.paphosTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (до 7 человек)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.paphosTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.paphosTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.paphosTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (до 11 человек)', isSelected: false, image: ToyotaMinibus, value: 1,destinations: pricing.paphosTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (до 19 человек)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.paphosTransfers[7].destinations},
        { id: 9, name: 'BUS (до 52 человек)', isSelected: false, image: Bus, value: 1, destinations: pricing.paphosTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (до 1 человека)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.paphosTransfers[9].destinations},
      ]
    }else{
      return [
        { id: 90, name: 'Мне не нужен транспорт', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Любой свободный автомобиль (до 6 человек)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.larnacaTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (до 4 человек)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.larnacaTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (до 4 человек)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.larnacaTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (до 7 человек)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.larnacaTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (до 7 человек)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (до 11 человек)', isSelected: false, image: ToyotaMinibus, value: 1, destinations: pricing.larnacaTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (до 19 человек)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.larnacaTransfers[7].destinations},
        { id: 9, name: 'BUS (до 52 человек)', isSelected: false, image: Bus, value: 1, destinations: pricing.larnacaTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (до 1 человека)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.larnacaTransfers[9].destinations},
      ]
    }
  }else{
    if(arrival === 'Larnaka' || departure === 'Larnaka'){
      return [
        { id: 90, name: 'I dont need a transport', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Any available car (up to 6 pax)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.larnacaTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (up to 4 pax)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.larnacaTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (up to 4 pax)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.larnacaTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (up to 7 pax)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.larnacaTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (up to 11 pax)', isSelected: false, image: ToyotaMinibus, value: 1, destinations: pricing.larnacaTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (up to 19 pax)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.larnacaTransfers[7].destinations},
        { id: 9, name: 'BUS (up to 52 pax)', isSelected: false, image: Bus, value: 1, destinations: pricing.larnacaTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (1 pax)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.larnacaTransfers[9].destinations},
      ]
    }else if(arrival === 'Pafos' || departure === 'Pafos'){
      return [
        { id: 90, name: 'I dont need a transport', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Any available car (up to 6 pax)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.paphosTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (up to 4 pax)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.paphosTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (up to 4 pax)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.paphosTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (up to 7 pax)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.paphosTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.paphosTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.paphosTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (up to 11 pax)', isSelected: false, image: ToyotaMinibus, value: 1,destinations: pricing.paphosTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (up to 19 pax)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.paphosTransfers[7].destinations},
        { id: 9, name: 'BUS (up to 52 pax)', isSelected: false, image: Bus, value: 1, destinations: pricing.paphosTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (1 pax)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.paphosTransfers[9].destinations},
      ]
    }else{
      return [
        { id: 90, name: 'I dont need a transport', isSelected: true, image: '', value: 0, destinations: []},
        { id: 1, name: 'Any available car (up to 6 pax)', isSelected: false, image: AnyCar, value: 1, destinations: pricing.larnacaTransfers[0].destinations},
        { id: 2, name: 'Mercedes E class (up to 4 pax)', isSelected: false, image: MercedesE, value: 1, destinations: pricing.larnacaTransfers[1].destinations},
        { id: 3, name: 'Mercedes S class (up to 4 pax)', isSelected: false, image: MercedesS, value: 1, destinations: pricing.larnacaTransfers[2].destinations},
        { id: 4, name: 'Mercedes Vito (up to 7 pax)', isSelected: false, image: MercedesVito, value: 1, destinations: pricing.larnacaTransfers[3].destinations},
        { id: 5, name: 'Mercedes V-class (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[4].destinations},
        { id: 6, name: 'Mercedes V-class VIP (up to 7 pax)', isSelected: false, image: MercedesV, value: 1, destinations: pricing.larnacaTransfers[5].destinations},
        { id: 7, name: 'Toyota minibus (up to 11 pax)', isSelected: false, image: ToyotaMinibus, value: 1, destinations: pricing.larnacaTransfers[6].destinations},
        { id: 8, name: 'Mercedes Sprinter (up to 19 pax)', isSelected: false, image: MersedesSprinter, value: 1, destinations: pricing.larnacaTransfers[7].destinations},
        { id: 9, name: 'BUS (up to 52 pax)', isSelected: false, image: Bus, value: 1, destinations: pricing.larnacaTransfers[8].destinations},
        { id: 10, name: 'Lamborghini (1 pax)', isSelected: false, image: Lamborgini, value: 1, destinations: pricing.larnacaTransfers[9].destinations},
      ]
    }
  }
  
}

export const generateServices = (flightData: string, pricing: IPricing): IFlight => {

  const language = localStorage.getItem('language');

    if(language && language === 'ru'){
      return {
        flightData: JSON.parse(flightData),
        selectedDestination: null,
        passengers: [
          {
            id: 1,
            name: 'adults',
            label: 'Взрослые, старше 12 лет',
            price: +pricing.adults,
            value: 0,
            isSelected: false
          },
          {
            id: 2,
            name: 'children',
            label: 'Дети, 2-12 лет',
            price: +pricing.children,
            value: 0,
            isSelected: false
          },
          {
            id: 3,
            name: 'infants',
            label: 'Младенцы, до 2 лет',
            price: +pricing.infants,
            value: 0,
            isSelected: false
          }
        ],
        taxDeclaration: [
          { id: 1, name: '0 чеков', price: +pricing.zeroRecepts, isSelected: true, value: 0 },
          { id: 2, name: '1 - 3 чека', price: +pricing.oneThreeRecepts, isSelected: false, value: 1 },
          { id: 3, name: '4 - 6 чеков', price: +pricing.fourSixRecepts, isSelected: false, value: 1 },
        ],
        extras: [
          { id: 1, name: 'VIP-зал', price: +pricing.vipLounge, isSelected: false, value: 0},
          { id: 2, name: 'Домашние животные', price: +pricing.pets, isSelected: false, value: 0},
          { id: 3, name: 'ПЦР-тест', price: +pricing.pcrTest, isSelected: false, value: 0},
        ],
        checkInLuggage: [
          {
            id: 1,
            name: 'checkInLuggage',
            label: 'Регистрация багажа',
            price: +pricing.checkInLuggage,
            value: 0,
            isSelected: false
          },
        ],
        specialLuggage: [
          { id: 1, name: 'Специальный багаж', price: +pricing.specialLuggage, isSelected: false, value: 0},
        ],
        transport: getTransport(JSON.parse(flightData).arrival, JSON.parse(flightData).departure, pricing),
        location: '',
        extraRequirements: '',
        cabinLuggage: [
          { id: 1, name: 'Каютный багаж', price: 0, isSelected: false, value: 0},
        ],
      }
    }else{
      return {
        flightData: JSON.parse(flightData),
        selectedDestination: null,
        passengers: [
          {
            id: 1,
            name: 'adults',
            label: 'Adults, over 12 years',
            price: +pricing.adults,
            value: 0,
            isSelected: false
          },
          {
            id: 2,
            name: 'children',
            label: 'Children, 2-12 years',
            price: +pricing.children,
            value: 0,
            isSelected: false
          },
          {
            id: 3,
            name: 'infants',
            label: 'Infants, under 2 years',
            price: +pricing.infants,
            value: 0,
            isSelected: false
          }
        ],
        taxDeclaration: [
          { id: 1, name: '0 Recepts', price: +pricing.zeroRecepts, isSelected: true, value: 0 },
          { id: 2, name: '1 - 3 Recepts', price: +pricing.oneThreeRecepts, isSelected: false, value: 1 },
          { id: 3, name: '4 - 6 Recepts', price: +pricing.fourSixRecepts, isSelected: false, value: 1 },
        ],
        extras: [
          { id: 1, name: 'VIP Lounge', price: +pricing.vipLounge, isSelected: false, value: 0},
          { id: 2, name: 'Pets', price: +pricing.pets, isSelected: false, value: 0},
          { id: 3, name: 'PCR Test', price: +pricing.pcrTest, isSelected: false, value: 0},
        ],
        checkInLuggage: [
          {
            id: 1,
            name: 'checkInLuggage',
            label: 'Check-in Luggage',
            price: +pricing.checkInLuggage,
            value: 0,
            isSelected: false
          },
        ],
        specialLuggage: [
          { id: 1, name: 'Special Luggage', price: +pricing.specialLuggage, isSelected: false, value: 0},
        ],
        transport: getTransport(JSON.parse(flightData).arrival, JSON.parse(flightData).departure, pricing),
        location: '',
        extraRequirements: '',
        cabinLuggage: [
          { id: 1, name: 'Cabin Luggage', price: 0, isSelected: false, value: 0},
        ],
      }
    }
}