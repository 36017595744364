// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shedulesListItem_shedulesListItemInfo__yQhZB {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}

.shedulesListItem_shedulesListItem__d8nCp {
  transition: 0.4s;
  cursor: pointer;
  display: block;
  padding-left: 29px;
  width: 233px;
  border-radius: 20px;
}
.shedulesListItem_shedulesListItem__d8nCp div {
  transition: 0.4s;
}
.shedulesListItem_shedulesListItem__d8nCp:hover {
  background: var(--dark-pink);
}
.shedulesListItem_shedulesListItem__d8nCp:hover div {
  color: var(--white);
}

.shedulesListItem_shedulesListItemRoute__qovR0 {
  color: var(--gray-100);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}

.shedulesListItem_shedulesListItem__d8nCp:not(:first-child) {
  margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ShedulesList/shedulesListItem.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,4BAAA;AAAR;AAEQ;EACI,mBAAA;AAAZ;;AAKA;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAFJ;;AAKA;EACI,gBAAA;AAFJ","sourcesContent":[".shedulesListItemInfo{\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 150%;\n}\n\n.shedulesListItem{\n    transition: 0.4s;\n    cursor: pointer;\n    display: block;\n    padding-left: 29px;\n    width: 233px;\n    border-radius: 20px;\n\n    div{\n        transition: 0.4s;\n    }\n\n    &:hover{\n        background: var(--dark-pink);\n\n        div{\n            color: var(--white);\n        }\n    }\n}\n\n.shedulesListItemRoute{\n    color: var(--gray-100);\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 150%;\n}\n\n.shedulesListItem:not(:first-child){\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shedulesListItemInfo": `shedulesListItem_shedulesListItemInfo__yQhZB`,
	"shedulesListItem": `shedulesListItem_shedulesListItem__d8nCp`,
	"shedulesListItemRoute": `shedulesListItem_shedulesListItemRoute__qovR0`
};
export default ___CSS_LOADER_EXPORT___;
