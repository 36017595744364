import React from 'react'
import Telephone from '../Telephone/Telephone';
import Telegram from '../../images/svg/telegram.svg';
import WhatsUp from '../../images/svg/whatsup.svg';
import styles from './headerContacts.module.scss';

const socials = [
    {id: 1, name: 'WhatsApp', href: 'https://wa.me/35797817779', icon: Telegram},
    {id: 2, name: 'Telegram', href: 'https://t.me/+35797817779', icon: WhatsUp},
]

const HeaderContacts = () => {
  return (
    <div className={styles.headerContacts}>
        <div className={styles.telephoneWrapper}>
            <Telephone />
        </div>
        <div className={styles.headerContactsSocialsLinks}>
            {socials.map((el, index) => (
                <a href={el.href} className={styles.headerContactsSocialsLinksItem} key={el.id}>
                    {el.name}
                    {index + 1 !== socials.length && ', '}
                </a>
            ))}
        </div>
        <div className={styles.headerContactsSocialsLinks}>
            {socials.map((el) => (
                <a href={el.href} className={styles.headerContactsSocialsLinksItem} key={el.id}>
                    <img loading='lazy' src={el.icon} alt={el.name} width={15} height={15} />
                </a>
            ))}
        </div>
    </div>
  )
}

export default HeaderContacts
