// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flightInputLabel_flightInputLabel__dF1IW {
  display: flex;
  align-items: center;
  color: var(--gray-100);
  font-size: 13px;
  font-weight: 400;
  line-height: 12px;
}
.flightInputLabel_flightInputLabel__dF1IW img {
  margin-left: 19px;
}

@media (max-width: 680px) {
  .flightInputLabel_flightInputLabel__dF1IW {
    position: absolute;
    top: 18px;
    left: 27px;
    width: 100%;
    min-width: 127px;
    max-width: 150px;
    justify-content: space-between;
    z-index: 7;
  }
  .flightInputLabel_flightInputLabel__dF1IW img {
    margin-left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/FlightInputLabel/flightInputLabel.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACI;EACI,iBAAA;AACR;;AAGA;EACI;IACI,kBAAA;IACA,SAAA;IACA,UAAA;IACA,WAAA;IACA,gBAAA;IACA,gBAAA;IACA,8BAAA;IACA,UAAA;EAAN;EAEM;IACI,cAAA;EAAV;AACF","sourcesContent":[".flightInputLabel{\n    display: flex;\n    align-items: center;\n    color: var(--gray-100);\n    font-size: 13px;\n    font-weight: 400;\n    line-height: 12px;\n\n    img{\n        margin-left: 19px;\n    }\n}\n\n@media(max-width: 680px){\n    .flightInputLabel{\n        position: absolute;\n        top: 18px;\n        left: 27px;\n        width: 100%;\n        min-width: 127px;\n        max-width: 150px;\n        justify-content: space-between;\n        z-index: 7;\n\n        img{\n            margin-left: 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flightInputLabel": `flightInputLabel_flightInputLabel__dF1IW`
};
export default ___CSS_LOADER_EXPORT___;
