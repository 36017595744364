// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileMenuButton_mobileMenuButton__\\+cO6p {
  display: none;
  margin-right: 9px;
  transform: translateY(-4px);
}

@media (max-width: 680px) {
  .mobileMenuButton_mobileMenuButton__\\+cO6p {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MobileMenuButton/mobileMenuButton.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;EACA,2BAAA;AACJ;;AAEA;EACI;IACI,cAAA;EACN;AACF","sourcesContent":[".mobileMenuButton{\n    display: none;\n    margin-right: 9px;\n    transform: translateY(-4px);\n}\n\n@media(max-width: 680px){\n    .mobileMenuButton{\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMenuButton": `mobileMenuButton_mobileMenuButton__+cO6p`
};
export default ___CSS_LOADER_EXPORT___;
